import React from 'react'
import { Row, Col } from 'react-bootstrap'


export default function HeaderSection() {
    return (
        <>
            <Row className="headerSection">
                <Col lg={6} >
                    <h1>Offset Your Carbon Footprint... <span className="boldWeight">Simply</span></h1>
                    <h4 className="grayFont mr-3">Localized CO<sub>2</sub> data, simple lifestyle questions, and globally traded carbon offset credits. <br></br>Offsetting your footprint each week is quick, easy, and low cost.</h4>
                    {/* <h1>Move Towards a Carbon Free Life... <span className="boldWeight">Simply</span></h1>
                    <h4 className="grayFont">For less than the cost of a cup of coffee each week<sup>*</sup> you can easily fund climate change projects and offset your household's weekly emissions.</h4> */}
                </Col>
                <Col lg={6}>
                    <img src="green-leaf-transparent.png" alt="Green Leaf with city illustration" width="95%" className="greenLeafCity"></img>
                </Col>
            </Row>
        </>
    )
}
