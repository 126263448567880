import React, { useState } from 'react'
import { Card, ListGroup, ListGroupItem, Form, Modal, Button } from 'react-bootstrap'

// import houseImage from "../images/home.png";

// const imageAddress = "https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1353&q=80";
const imageAddress = "/house.png";

function HomeCard({ selectionChange, state }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
        selectionChange("entryChanged", 1);
    };

    return (
        <>
            <Card className="mb-3 mx-auto calculatorCards" style={{ width: '20rem' }}>
                <i id="infoButton" onClick={handleShow} className="far fa-question-circle"></i>
                <Card.Img variant="top" src={imageAddress} className="calculatorCardImage" />
                <Card.Body>
                    <Card.Title>Your Home This Past Week</Card.Title>
                    <Card.Text>
                        <i>The impact of your electricity and heat</i>

                    </Card.Text>
                </Card.Body>
                <ListGroup
                    className="list-group-flush"
                >
                    <ListGroupItem>

                        <Form.Control
                            disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                            value={state.homeSelection} as="select" custom className="mt-0" id="homeSelection" onChange={(e) => callStateFunction("homeSelection", e.target.value)}>
                            <option value={1}>How big is your home?</option>
                            <option value={750}>500-999 square feet home</option>
                            <option value={1500}>1,000-1,999 square feet home</option>
                            <option value={2500}>2,000-2,999 square feet home</option>
                            <option value={3500}>3,000-3,999 square feet home</option>
                            <option value={4500}>4,000+ square feet home</option>
                        </Form.Control>

                        <Form.Control
                            disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                            value={state.electricitySelection} as="select" custom className="mt-3" id="electricitySelection" onChange={(e) => callStateFunction("electricitySelection", e.target.value)}>
                            <option value={1}>Any electricity from renewables?</option>
                            <option value={100}>100% electricity from renewables</option>
                            <option value={75}>75% electricity from renewables</option>
                            <option value={50}>50% electricity from renewables</option>
                            <option value={25}>25% electricity from renewables</option>
                            <option value={0}>No electricity from renewables</option>
                        </Form.Control>

                        <Form.Control
                            disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                            value={state.heatingSelection} as="select" custom className="mt-3" id="heatingSelection" onChange={(e) => callStateFunction("heatingSelection", e.target.value)}>
                            <option value={1}>Any heating from renewables?</option>
                            <option value={100}>100% heating from renewables</option>
                            <option value={75}>75% heating from renewables</option>
                            <option value={50}>50% heating from renewables</option>
                            <option value={25}>25% heating from renewables</option>
                            <option value={0}>No heating from renewables</option>
                        </Form.Control>

                        <Card.Text className="mt-1" style={{ textAlign: "center" }}>
                            <i>{Math.round(state.footprintHome)} lbs CO<sub>2</sub></i>
                        </Card.Text>

                    </ListGroupItem>
                </ListGroup>
            </Card>

            {/* Information modal for the Home CO2 panel */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>"Home" Sources of CO<sub>2</sub></Modal.Title>
                </Modal.Header>
                <Modal.Body>To determine your Home CO<sub>2</sub> production, we gather the following data for your state:<br></br><br></br>
                    <ul>
                        <li>Average home size.</li>
                        <li>Average CO<sub>2</sub> production per person per week for electricity and heating.</li>
                        <li>Average percentage of CO<sub>2</sub> from electricity vs. heating.</li>
                    </ul>
                    Then we scale your CO<sub>2</sub> contribution based on your home size and number of people you are entering for.
                    Finally, we decrease your "Home" footprint for the past week given the percentage of electricity and heat that you are receiving from renewable sources.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>



    )
}

export default HomeCard


