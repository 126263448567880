// import React from 'react'
// import { Redirect } from 'react-router-dom';
// import { Button, Container, Col, Row } from 'react-bootstrap'


function Logout({ selectionChange }) {

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    // Reset key variables at logout and redirect to home screen
    callStateFunction("userID", "None");
    callStateFunction("token", "");
    callStateFunction("validToken", 0);
    callStateFunction("firstName", "");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("firstName", "")
    window.location.pathname = '/'
    sessionStorage.setItem('currentState', "");
    sessionStorage.setItem('purchaseSaveComplete', 'No');
    sessionStorage.setItem('purchaseSuccess', 'No');
    sessionStorage.setItem('firstCardSetComplete', 'No');

    // return (

    //     <Container className="mb-3" style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px', maxWidth: '800px' }}>

    //         <Row>

    //             <Col
    //                 className="calculateIntroLeft">


    //                 <span class="helper"></span>
    //                 <img src="calculate-background.png" alt="Background" style={{ width: '100%', verticalAlign: 'middle' }}></img>
    //                 <div className="centerTextInImage">
    //                     <h2><span className="boldWeight">Thank you</span> for making a difference.</h2>
    //                     <button className="pageButton" onClick={() => {
    //                         callStateFunction("userID", "None");
    //                         callStateFunction("token", "");
    //                         callStateFunction("validToken", 0);
    //                         callStateFunction("firstName", "");
    //                         sessionStorage.setItem("token", "");
    //                         sessionStorage.setItem("firstName", "")
    //                         window.location.pathname = '/'
    //                     }}>Logout</button>
    //                 </div>
    //             </Col>
    //         </Row>
    //     </Container>





    // )
}

export default Logout
