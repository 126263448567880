import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'

export default function ProcessSection() {
    return (
        <>
            <Row>
                <div className="mx-auto" style={{ textAlign: 'center', marginTop: '100px', maxWidth: '900px' }}>
                    <h2><span className="boldWeight">Quickly and easily</span> check in each week and learn from your CO<sub>2</sub> footprint.</h2><br></br>
                    <p className="pPlus grayFont">We provide you the tools to easily reach an estimate of your footprint without any detailed knowledge of your exact energy use.</p><br></br>
                </div>
            </Row>
            <Row style={{ marginTop: '0px', overflow: 'hidden' }} className="justify-content-center verticalCenter">
                {/* <Col className="justify-content-center" lg={3}> */}
                <Col md="auto" lg={3}>
                    <Card className="mb-5 mt-5 processCard">
                        <Card.Img className="mt-1" variant="top" src="email.png" />
                        <Card.Body>
                            <Card.Title><h4 className="boldWeight">Update</h4>
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">Receive weekly emails with a link to quickly check in with auto-populated entries.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="auto" lg={3}>
                    <Card className="mb-5 mt-5 processCard">
                        <Card.Img className="mt-1" variant="top" src="questions.png" />
                        <Card.Body>
                            <Card.Title><h4 className="boldWeight">Review</h4>
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">Answer a few simple questions and determine your footprint for the week.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="auto" lg={3}>
                    <Card className="mb-5 mt-5 processCard">
                        <Card.Img className="mt-1" variant="top" src="progress.png" />
                        <Card.Body>
                            <Card.Title><h4 className="boldWeight">Evaluate</h4>
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">Reflect on how your choices can impact your footprint in the coming week.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="auto" lg={3}>
                    <Card className="mb-5 mt-5 processCard">
                        <Card.Img className="mt-1" variant="top" src="purchase.png" />
                        <Card.Body>
                            <Card.Title><h4 className="boldWeight">Support</h4>
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">Purchase certified offset credits to balance your week and make a difference.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
