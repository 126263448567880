import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavbarMenu from "./components/NavbarMenu"
import Home from "./pages/Home/Home"
import LoginOnly from "./pages/Login/LoginOnly"
import StripeReturn from "./pages/Login/StripeReturn"
import RegisterOnly from "./pages/Login/RegisterOnly"
import PasswordChange from "./pages/Login/PasswordChange"
import Logout from "./pages/Logout/Logout"
// import EmailAuth from "./pages/Logout/EmailAuth"
import NoMatch from "./pages/NoMatch/NoMatch"
import About from "./pages/About/About"
import UPCO2_Records from "./pages/UPCO2_Records/UPCO2_Records"
import Resources from "./pages/Resources/Resources"
// import Contact from "./pages/Contact/Contact"
import Purchase from "./pages/Purchase/Purchase"
import History from "./pages/History/History"
import Dashboard from "./pages/Dashboard/Dashboard"
import ReDashboard from "./pages/Dashboard/ReDashboard"
import Container from "react-bootstrap/Container"
import ScrollToTop from "../src/components/ScrollToTop"

require("dotenv").config();

const FootprintCost = require("./FootprintCost");

var currentYear = new Date().getFullYear();

class App extends Component {

  state = {  // Note - when a user makes an offset purchase, the entire state variable is saved

    // Variable to signal that something changed from initial load of last week's #s
    entryChanged: 0,

    // User Data and Date
    userID: 'None',  // User ID  Automatically created in users DB collection when user first registered
    token: '',  // Token for verifying the user, 
    validToken: 0, // 0 = false, 1 = true
    firstName: '',  // User's first name
    date: new Date(new Date().toLocaleDateString()),
    //date: new Date(), // Today's date

    // Data for Home Card Calculations
    state: 1, // User's state they are entering data for
    resElecCO2PerPersonPerSQFT: 1, // From state DB collection
    resHeatCO2PerPersonPerSQFT: 1, // From state DB collection
    numPeople: 0,  // Number of people the user is entering for
    homeSelection: 1,  // Size of home the user selected
    electricitySelection: 1,  // % Electricity from renewables that the user entered
    heatingSelection: 1, // % Heat from renewables that the user entered
    footprintHome: 0, // Resultant Home footprint

    // Data for Transportation Card Calculations
    numVehicles: -1,
    mpgSelection1: 1,
    milesSelection1: 1,
    mpgSelection2: 1,
    milesSelection2: 1,
    mpgSelection3: 1,
    milesSelection3: 1,
    mpgSelection4: 1,
    milesSelection4: 1,
    footprintTransportationCar1: 0,
    footprintTransportationCar2: 0,
    footprintTransportationCar3: 0,
    footprintTransportationCar4: 0,
    footprintTransportation: 0,

    // Data for Life Card Calculations
    dietSelection: 1,
    recyclingSelection: 1,
    consumerSelection: 1,
    footprintLife: 0, // Resultant Life footprint

    // Calculation Constants read in from constants db
    constants: [],

    // Results
    footprint: 0, // Total Footprint
    cost: 0,  // Total Charge to user

    // Misc program flow items
    inStripeReturn: false
  };

  // Load CO2 constants from the database on the page load - to use in footprint and cost calculations
  componentDidMount() {
    fetch("/api/constants")
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ constants: data })
      });
  }

  onSelectionChange = (selection, value) => {

    // Capture that something has changed from previous week's entry

    if ((selection === "state" && value !== "1") || selection === "userID" || selection === "token") {
      this.setState({
        [selection]: value.toString()
      })
      console.log("state: ", selection, value);
      // Now if it was a state change, need to load in new values for that state
      if (selection === "state") {
        fetch(`/api/state/${value}`)
          .then(response => {
            return response.json();
          })
          .then(ReturnData => {
            console.log("state data returned:", ReturnData)
            this.setState({
              resElecCO2PerPersonPerSQFT: ReturnData[0].resElecCO2PerPersonPerSQFT,
              resHeatCO2PerPersonPerSQFT: ReturnData[0].resHeatCO2PerPersonPerSQFT,
            }, () => {
              this.setState(this.calculateFootprint())
            })
          });
      }
    }
    else if (selection === "footprintHome") {
      this.setState({
        [selection]: value
      }, () => {
        this.setState(this.calculateFootprint())
      })
    }
    else if (selection === "firstName") {
      this.setState({
        [selection]: value.toString()
      }, () => {
        this.setState(this.calculateFootprint())
      })
    }
    else if (selection === "lastPurchaseDate") {
      this.setState({
        [selection]: new Date(value)
      }, () => {
        this.setState(this.calculateFootprint())
      })
    }
    // Constants coming from StripeReturn page.  Don't calculate footprint
    else if ((selection === "constants") || (selection === "inStripeReturn")) {
      this.setState({
        [selection]: value
      }, () => {
        // this.setState(this.calculateFootprint())
      })
    }
    else {
      this.setState({
        [selection]: parseInt(value)
      }, () => {
        this.setState(this.calculateFootprint())
      })
    }
  }

  calculateFootprint = () => {
    let calcCost;
    const footprintValues = FootprintCost.footprint(this.state);
    // const calcFootprint = FootprintCost.footprint(this.state);
    // console.log("footprintValues: ", footprintValues);
    const co2Home = footprintValues[0];
    const co2Transportation = footprintValues[1];
    const co2Life = footprintValues[2];
    const calcFootprint = footprintValues[3];
    // Check to see if the constants are present.  If not, load from session storage
    if (typeof this.state.constants[0] !== 'undefined' && this.state.constants[0] && this.state.constants[0].costRate) {
      calcCost = FootprintCost.cost(calcFootprint, this.state.constants[0]);
    }
    else {
      // No constants, so read currentSate from session storage and then run the cost calculation
      this.state = JSON.parse(sessionStorage.getItem('currentState'));
      calcCost = FootprintCost.cost(calcFootprint, this.state.constants[0]);
    }
    // Each time the state is updated, save it to local storage
    // sessionStorage.setItem('currentState', JSON.stringify(this.state));
    return { footprint: calcFootprint, cost: calcCost, footprintHome: co2Home, footprintTransportation: co2Transportation, footprintLife: co2Life }
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <div>
          <Container fluid className="outerContainer" >
            <NavbarMenu
              // userID={this.state.userID}
              selectionChange={this.onSelectionChange}
              state={this.state}
            />
            <Container fluid className="mainContainer justify-content-center">
              <Switch>
                <Route exact path={["/", "/home"]}>
                  <Home
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>
                <Route exact path="/dashboard">
                  <Dashboard
                    selectionChange={this.onSelectionChange}
                    state={this.state} />
                </Route>
                <Route exact path="/redashboard">
                  <ReDashboard
                    selectionChange={this.onSelectionChange}
                    state={this.state} />
                </Route>
                <Route path="/login">
                  <LoginOnly
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>
                <Route path="/stripeReturn">
                  <StripeReturn
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>

                {/* <Route path="/confirm/:confirmationCode"> */}
                <Route path="/confirm/:confirmationCode">
                  <LoginOnly
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                    mode={"confirm"}
                  />

                  {/* <EmailAuth
                /> */}

                </Route>

                <Route path="/register">
                  <RegisterOnly
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>
                <Route path="/passwordChange">
                  <PasswordChange
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>
                <Route path="/logout">
                  <Logout
                    selectionChange={this.onSelectionChange}
                  />
                </Route>
                <Route path="/about">
                  <About
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>
                <Route path="/purchases">
                  <UPCO2_Records
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>
                <Route path="/resources">
                  <Resources
                    selectionChange={this.onSelectionChange}
                    state={this.state}
                  />
                </Route>
                {/* <Route path="/contact">
                <Contact />
              </Route> */}
                <Route path="/purchase">
                  <Purchase
                    state={this.state}
                  />
                </Route>
                <Route path="/history">
                  <History
                    userID={this.state.userID}
                  />
                </Route>
                <Route>
                  <NoMatch />
                </Route>
              </Switch>
            </Container>
            <footer>
              <div>
                <hr></hr>
                <p>
                  Simply Carbon Neutral is a product of Pointer Solutions, LLC | &copy; {currentYear} <br></br>
                  <a style={{ color: "white", textDecoration: "underline" }} href="/about#policy">Privacy & Data Collection Policies</a>
                  <span> | Fort Collins, Colorado<br></br>
                    Design by </span>
                  <a style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/mychalmartinez/">Mychal Martinez</a>
                </p>
                <p><small>Licensed Icons by <a style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" href="https://icons8.com">Icons8</a>
                  <span> | Licensed Graphics by </span>
                  <a style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" href="https://www.vecteezy.com/">Vecteezy</a><br></br>
                  <sup>*</sup> Based on the average CO<sub>2</sub> output of an individual American.
                </small></p>

              </div>
            </footer>
          </Container>
        </div>
      </Router >
    );
  }
}

export default App;
