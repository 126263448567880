import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'


export default function WhyCarbonOffsetSection() {
    return (
        <Row style={{ paddingTop: '150px', paddingBottom: '150px' }} className="calculateSecondSection verticalCenter">
            <Col lg={6} >
                <h2><span className="boldWeight">Why</span> is my carbon <br></br>footprint important?</h2>
                <p className="pPlus grayFont">Simply stated, when we burn fossil fuels, we release CO<sub>2</sub> and other greenhouse gases into our atmosphere, which heats our planet over time and damages the environment. See our
                    <Link to="/resources"> Resources page </Link> to learn more about CO<sub>2</sub> and climate change.</p>
                <br></br>
                <h4 className="grayFont">You can learn to reduce your footprint and fund useful projects for only $2.50 per week.<sup>*</sup></h4>
                <Link to="/login">

                    <button className="pageButton" type="button">Get Started</button>
                </Link>

            </Col>
            <Col lg={6}>
                {/* <img src="wind-turbine-illustration.jpg" width="90%" className="windTurbineIllustration"></img> */}
                <img src="wind-turbine-illustration.jpg" alt="Wind turbine illustration" className="windTurbineIllustration"></img>
            </Col>
        </Row>

    )
}
