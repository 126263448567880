import React, { useEffect } from 'react'
// import ReactDOM from "react-dom"
import { Card } from 'react-bootstrap'
// import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const imageAddress = "/checkmark.png";

let purchaseSaveComplete = "";

function PurchaseCard({ selectionChange, state }) {

    // If there hasn't been a successful storage, save the full state to local storage each time something changes, 
    // so that state is available when there is a purchase.
    // Also check to make sure we aren't storing a blank state by checking the state.state variable - the first thing the user sets
    console.log("state.state: ", state.state);
    if ((sessionStorage.getItem('purchaseSuccess') === 'No') && (state.state !== 1)) {
        console.log("saving currentState");
        sessionStorage.setItem('currentState', JSON.stringify(state));
    }


    // Read the purchaseSaveComplete variable so that the button choice can use it below.
    purchaseSaveComplete = sessionStorage.getItem('purchaseSaveComplete');

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    // Hack to work around React's inability to refresh the current route.
    const location = useLocation();
    console.log(location.pathname);
    let pathToLoad = "";

    if ((location.pathname === "/login") || (location.pathname === "/register")) {
        pathToLoad = "/dashboard";
    }
    else if (location.pathname === "/dashboard") {
        pathToLoad = "/redashboard";
    }
    else {
        pathToLoad = "/dashboard";
    }


    useEffect(() => {
        // Read the purchaseSaveComplete variable so that the button choice can use it below.
        purchaseSaveComplete = sessionStorage.getItem('purchaseSaveComplete');
        // If the purchase is complete, then jump to the Doughnut Graph and display a tip
        if (purchaseSaveComplete === 'Yes') {
            window.location.href = "#doughnutGraph"
        }

    }, [2]);

    return (
        <>
            <Card className="mb-3 mx-auto calculatorCards" style={{ width: '20rem' }}>

                <Card.Img variant="top" style={{ marginTop: '20px' }} src={imageAddress} className="calculatorCardImage" />
                <Card.Body style={{ textAlign: 'center' }}>
                    <Card.Title>You produced</Card.Title>
                    <Card.Text><h2 className="boldWeight">{Math.round(state.footprint) + " lbs."}</h2></Card.Text>
                    <Card.Title>of CO<sub>2</sub> this week.</Card.Title>
                    <Card.Title>{"$" + (state.cost).toFixed(2) + " to offset."}</Card.Title>
                </Card.Body>

                <Card.Body style={{ textAlign: 'center', marginTop: '-30px' }}>
                    {/* Check to see which button to display. */}
                    {(purchaseSaveComplete === 'Yes')
                        ?
                        /* They have already purchased, so grey out and give note that they are done. */
                        <button type="button" disabled={true}>Well Done!<br></br>Your offset is complete for this week!</button>
                        :
                        /* Purchase isn't done, so check to see if all cards are filled out */
                        ((state.state !== 1) && (state.numPeople !== 0) && (state.numVehicles !== -1) &&
                            (state.homeSelection !== 1) && (state.electricitySelection !== 1) && (state.heatingSelection !== 1) &&
                            (state.dietSelection !== 1) && (state.recyclingSelection !== 1) && (state.consumerSelection !== 1) &&
                            // Now the vehicles check 
                            // Vehicle 1 settings are entered, or there is < 1 vehicle
                            (((state.mpgSelection1 !== 1) && (state.milesSelection1 !== 1)) || (state.numVehicles < 1)) &&
                            // Vehicle 2 settings are entered, or there are < 2 vehicles
                            (((state.mpgSelection2 !== 1) && (state.milesSelection2 !== 1)) || (state.numVehicles < 2)) &&
                            // Vehicle 3 settings are entered, or there are < 3 vehicles
                            (((state.mpgSelection3 !== 1) && (state.milesSelection3 !== 1)) || (state.numVehicles < 3)) &&
                            // Vehicle 4 settings are entered, or there are < 4 vehicles
                            (((state.mpgSelection4 !== 1) && (state.milesSelection4 !== 1)) || (state.numVehicles < 4))

                        )
                            ?
                            /* All filled out, so trigger Stripe purchase */
                            /* Stripe Button */
                            <form action="/api/create-checkout-session"
                                method="POST">
                                {/* Pass state.cost to Stripe */}
                                <select id="price" name="price" style={{ display: 'none' }}>
                                    <option value={state.cost}></option>
                                </select>
                                {/* Pass state.footprint to Stripe */}
                                <select id="footprint" name="footprint" style={{ display: 'none' }}>
                                    <option value={state.footprint}></option>
                                </select>
                                <button type="submit" className="pPlus">
                                    Simulate Purchasing Offset!
                                </button>
                            </form>
                            :
                            /* Not filled out, so instruct user */
                            <button type="button" disabled={true}><i>Purchase Offset!</i><br></br>Please answer all question cards.</button>
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default PurchaseCard


