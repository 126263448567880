// import React from 'react'
import React, { useState } from "react";
// import Home from "../Home/Home"
// import Wrapper from "../../components/Wrapper"
import Dashboard from "../Dashboard/Dashboard"

import { Link } from 'react-router-dom'

import { Container, Button, Form, Col, Row, Modal } from 'react-bootstrap'

// largeScreen will be false if screen is < 993px.  
// image is set to leaf-car.png , assuming we are on a small screen.
// If largeScreen is true, then the image is changed to leaf-car-rot.png. 
const largeScreen = window.matchMedia("(min-width: 992px)").matches;
let registerImage = "leaf-car.png";
if (largeScreen) {
    registerImage = "leaf-car-rot.png";
}

// let registrationComplete = false;

function Login({ selectionChange, state }) {

    const [userState, setUserState] = useState({
        email: "",
        password: "",
        regEmail: "",
        regPassword: "",
        regPasswordCheck: "",
        firstName: "",
        lastName: ""
    });

    // Variables for displaying the registration modal with email confirmation directions
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        window.location.href = "/";
    }
    const handleShow = () => setShow(true);
    //

    const myChangeHandler = (event) => {
        const { name, value } = event.target;
        setUserState({ ...userState, [name]: value })
    };

    function ValidateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return (true)
        }
        alert("You have entered an invalid email address!")
        return (false)
    }

    function handleRegistrationFormSubmit(event) {
        event.preventDefault();
        if (ValidateEmail(userState.regEmail)) {

            if (userState.regPassword === userState.regPasswordCheck) {
                if (userState.regEmail && userState.regPassword && userState.firstName && userState.lastName) {
                    // Create the email confirmation code
                    // const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                    // let confirmationCode = '';
                    // for (let i = 0; i < 25; i++) {
                    //     confirmationCode += characters[Math.floor(Math.random() * characters.length)];
                    // }
                    //
                    fetch("/api/register", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        // Added the email confirmation code below
                        // body: JSON.stringify({ "firstName": userState.firstName, "lastName": userState.lastName, "email": userState.regEmail, "password": userState.regPassword, "confirmationCode": confirmationCode }),
                        body: JSON.stringify({ "firstName": userState.firstName, "lastName": userState.lastName, "email": userState.regEmail, "password": userState.regPassword }),
                    })
                        .then(res => {
                            // console.log("res", res);
                            if (res.status === 500) {
                                // console.log("status: ", res.status);
                                callStateFunction("userID", "None")
                                alert("Registration Failed. Please try again. Note, the same email cannot be registered more than once.");
                            }
                            return res.json();
                        }).then(data => {
                            // console.log(data);
                            if (data._id) {
                                // User registered, but now we have to wait for the confirmation email.
                                // So now display a message and redirect back to the home page
                                handleShow();

                                // alert("CHANGE TO MODAL; Thank you for registering. Please check your email for a communication from XXX and click on the confirmation button. Make sure to check any junk or spam folders. Once your email has been verified, you will be able to log in.");
                                // Now redirect back to the homepage
                                // window.location.href = "/";

                                // Save the token, userID, and firstName to local storage
                                // sessionStorage.setItem("token", data.token);
                                // sessionStorage.setItem("userID", data.user._id)
                                // sessionStorage.setItem("firstName", data.data.firstName)

                                // Set state variables for user
                                // callStateFunction("userID", data.data._id)
                                // callStateFunction("firstName", data.data.firstName)




                                // callStateFunction("userID", data._id)
                            } else {
                                console.log("no registration");
                            }
                        });
                }
            } else {
                alert("The two passwords entered do not match.  Please try again.");
            }
        }
    };

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };


    return (
        <div>
            {(state.userID === "None") ?
                <Container className="mb-3" style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                    <h1 className="pt-3 mb-3 h3Plus" style={{ textAlign: 'center' }}>Please <span className="boldWeight">Register</span> Below</h1>
                    <Row>
                        <Col lg={4}>
                            <div>
                                {/* <img src="wind-turbine-illustration.jpg" className="windTurbineIllustrationLogin"></img> */}
                                <img src={registerImage} alt="Renewables illustration with car" className="registerImg"></img>
                            </div>
                        </Col>
                        <Col lg={8}>

                            {/* Registration Form */}

                            <Form style={{ marginTop: "20px", fontSize: 'large' }}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control name="firstName" type="text" placeholder="Enter first name" value={userState.firstName} onChange={myChangeHandler} />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control name="lastName" type="text" placeholder="Enter last name" value={userState.lastName} onChange={myChangeHandler} />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control name="regEmail" type="email" placeholder="Enter email" value={userState.regEmail} onChange={myChangeHandler} />
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control name="regPassword" type="password" placeholder="Password" value={userState.regPassword} onChange={myChangeHandler} />
                                    <Form.Text className="text-muted">
                                        Passwords are encrypted and not stored in clear text.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formBasicPasswordCheck">
                                    <Form.Label>Reenter Password</Form.Label>
                                    <Form.Control name="regPasswordCheck" type="password" placeholder="Password" value={userState.regPasswordCheck} onChange={myChangeHandler} />
                                </Form.Group>
                                <button type="button"
                                    disabled={!(userState.regEmail && userState.regPassword && userState.firstName && userState.lastName)}
                                    onClick={handleRegistrationFormSubmit}>
                                    Register
                                </button>
                            </Form>

                            <Form.Label>
                                <br></br>
                                Already have an account?  <Link to="/login">Login here.</Link>
                            </Form.Label>
                        </Col>
                    </Row>

                </Container>

                : <Dashboard
                    selectionChange={selectionChange}
                    state={state}
                />
            }


            {/* Modal window used for registration note to check email for verification */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Email Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Thank you for registering. Please check your email for a communication from support@simplycarbonneutral.com and click on the confirmation link. Make sure to check any junk or spam folders. Once your email has been verified, you will be able to log in.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>




        </div >
    )
}

export default Login

