import React, { useState, useEffect } from "react";
import { Container, Modal, Button } from 'react-bootstrap'
import HeaderSection from "../../components/HeaderSection"
import CalculateIntroSection from "../../components/CalculateIntroSection"
import ProcessSection from "../../components/ProcessSection"
import ChallengesSection from "../../components/ChallengesSection"
import TokenSection from "../../components/TokenSection"
import OffsetsSection from "../../components/OffsetsSection"
// import CalculateSecondSection from "../../components/CalculateSecondSection"
import WhyCarbonOffsetSection from "../../components/WhyCarbonOffsetSection"
import MissionSection from "../../components/MissionSection"
import ContactSection from "../../components/ContactSection"
// import { Link } from 'react-router-dom'


function Home({ selectionChange, state }) {

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    useEffect(() => {

        // Set inStripeReturn to false so login and user will be displayed in navbar
        callStateFunction("inStripeReturn", false);

    }, []);


    return (
        <>
            <Container fluid style={{ paddingLeft: '0', paddingRight: '0' }}>

                <Container fluid style={{ backgroundColor: 'white' }} >
                    <HeaderSection />
                </Container>

                <Container fluid style={{ backgroundColor: 'white' }}>
                    <CalculateIntroSection />
                </Container>

                <Container fluid style={{ backgroundColor: 'white' }} >
                    <ProcessSection />
                </Container>

                <Container fluid style={{ backgroundColor: 'white' }} >
                    <ChallengesSection />
                </Container>

                <Container fluid style={{ backgroundColor: '#296F50', color: 'white' }} >
                    <TokenSection />
                </Container>

                {/* <Container fluid style={{ backgroundColor: '#F1F1F1' }} >
                    <CalculateSecondSection />
                </Container> */}

                <Container fluid style={{ backgroundColor: '#F1F1F1' }} >
                    <WhyCarbonOffsetSection />
                </Container>

                <Container fluid style={{ backgroundColor: '#296F50', color: 'white' }} >
                    <OffsetsSection />
                </Container>

                <Container fluid >
                    <MissionSection />
                </Container>

                <Container fluid style={{ backgroundColor: '#296F50' }}  >
                    <ContactSection />
                </Container>

            </Container>


        </>
    )
}

export default Home
