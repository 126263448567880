import React, { useState, useEffect } from "react";
// import Home from "../Home/Home"
import Dashboard from "../Dashboard/Dashboard"
// import Wrapper from "../../components/Wrapper"
import { Link } from 'react-router-dom'
import { Container, Button, Row, Col, Form, Modal } from 'react-bootstrap'

let emailVerified = 0;
let scnPath = '';

function Login({ selectionChange, state, mode }) {

    const [userState, setUserState] = useState({
        email: "",
        password: "",
        regEmail: "",
        regPassword: "",
        regPasswordCheck: "",
        firstName: "",
        lastName: ""
    });

    // Variables for displaying the email confirmation modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //
    // Variables for displaying the Stripe Success modal
    const [showStripeSuccess, setShowStripeSuccess] = useState(false);
    const handleCloseStripeSuccess = () => setShowStripeSuccess(false);
    const handleShowStripeSuccess = () => setShowStripeSuccess(true);
    //
    // Variables for displaying the Stripe Canceled modal
    const [showStripeCanceled, setShowStripeCanceled] = useState(false);
    const handleCloseStripeCanceled = () => setShowStripeCanceled(false);
    const handleShowStripeCanceled = () => setShowStripeCanceled(true);
    //

    const myChangeHandler = (event) => {
        const { name, value } = event.target;
        setUserState({ ...userState, [name]: value })
    };

    function ValidateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return (true)
        }
        alert("You have entered an invalid email address!")
        return (false)
    }

    function handleLoginFormSubmit(event) {
        event.preventDefault();

        if (ValidateEmail(userState.email)) {

            if (userState.email && userState.password) {
                fetch("/api/authenticate", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "email": userState.email, "password": userState.password }),
                })
                    .then(res => {
                        return res.json()
                    }).then(data => {
                        console.log("user: " + JSON.stringify(data));
                        console.log("data.error: " + data.error);
                        if (!data.error) {
                            if (data.user._id) {
                                // callStateFunction("token", data.token)

                                // Save the token, userID, and firstName to local storage
                                sessionStorage.setItem("token", data.token);
                                sessionStorage.setItem("firstName", data.user.firstName)

                                // Set state variables for user
                                callStateFunction("userID", data.user._id)
                                callStateFunction("firstName", data.user.firstName)

                                // Initialize other local storage variables
                                sessionStorage.setItem('currentState', "");
                                // sessionStorage.setItem('purchaseSaveComplete', 'No');
                                // sessionStorage.setItem('purchaseSuccess', 'No');

                            } else {
                                alert("Login failed.  Please try again.");
                            }
                        }
                        else {
                            alert(data.error);
                        }

                    });
            }
        }
    };

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };



    useEffect(() => {

        // Set inStripeReturn to false so login and user will be displayed in navbar
        callStateFunction("inStripeReturn", false);

        // Initial check to see if there is a token in local storage 
        const token = sessionStorage.getItem("token");

        if (token === null) {
            // There is no token in storage, so user need to log in.  Set verifiedToken to false
            console.log("There was no token in local storage");
        } else {
            // Token in storage, now need to verify it 
            console.log("There is a token in local storage, beginning check");
            fetch("/checkToken", {
                method: 'GET',
                headers: {
                    'x-access-token': token,
                },
            })
                .then(res => {
                    return res.json()
                }).then(data => {
                    if (typeof data === 'string') {
                        // Token is valid, set verifiedToken to true
                        console.log("Token was valid");
                        // alert("Token was valid. Data returned: " + data);
                        // verifiedToken = true;
                        // let userID = sessionStorage.getItem("userID");
                        let firstName = sessionStorage.getItem("firstName");
                        callStateFunction("userID", data);
                        callStateFunction("firstName", firstName);
                    } else {
                        // Token is invalid, keep verifiedToken set to false
                        console.log("Token was invalid");
                        // alert("Token was invalid");
                    }
                });
        }

        // Confirm email section
        // Determine if the user is coming from the confirm email link
        if (mode === "confirm") {
            // Get the path variable from the back end

            console.log("Mode is confirm and have started this if");

            fetch("/api/getPath")
                .then(response => {
                    return response.json();
                })
                .then(ReturnData => {
                    console.log("ReturnData: " + ReturnData);
                    scnPath = ReturnData;
                    console.log("scnPath: " + scnPath);

                    // Now we know the path for the version we are running.
                    // Now build the full path and retrieve the confirmation code from the path

                    //Store the current path
                    const currentPath = window.location.href;
                    // Build the path header
                    const pathHeader = scnPath.concat('/confirm/');
                    //Now we need to get the email verification code off of the path
                    const authCode = currentPath.replace(pathHeader, "");
                    console.log("authCode: " + authCode);
                    console.log("pathHeader: " + pathHeader);

                    // Now we need to call the route to find the user with this authCode and change their status to 
                    // Active, and then allow them to login

                    // Set the route
                    const authRoute = '/api/confirm/'.concat(authCode);
                    // }

                    fetch(authRoute)
                        .then(response => {
                            return response.json();
                        })
                        .then(ReturnData => {
                            // If the user has been found and the route was successful, we need to open the modal and display a success message.
                            // If not successful we need to open the modal and display the error.
                            // Determine what came back here and set the modal message.
                            //
                            //
                            //  *** HERE *** NEED TEST TO SEE IF PROCESS WAS NOT SUCCESSFUL AND CHANGE THE MESSAGE
                            // Tmp data echo
                            console.log("Confirm email route ReturnData: " + JSON.stringify(ReturnData));
                            // console.log("Number of users modified: " + JSON.stringify(ReturnData.nModified));
                            // Test to see if a user was found and modified
                            // if (ReturnData.nModified === 1) {
                            if (ReturnData.modifiedCount === 1) {
                                // Email verified, so set emailVerified variable to 1
                                emailVerified = 1;
                                // } else if (ReturnData.n === 1 && ReturnData.nModified === 0 && ReturnData.ok === 1) {
                            } else if (ReturnData.matchedCount === 1 && ReturnData.modifiedCount === 0 && ReturnData.acknowledged === true) {
                                // Email was already previously verified.  Set emailVerified to 2
                                emailVerified = 2;
                            } else {
                                // Email not verified, so keep emailVerified variable set to 0
                            }
                            // Now need to set the variable to display the modal
                            handleShow();
                        });
                })

        }

        // Stripe purchase return routes

        // Get the any parameters off of the current route
        const query = new URLSearchParams(window.location.search);

        // If there are parameters, take action on stripeSuccess or stripeCanceled

        if (query.get("stripeSuccess")) {
            // Purchase was complete, set local storage variable
            sessionStorage.setItem('purchaseSuccess', 'Yes');
            // Set a local storage variable so we know we're returning from Stripe
            // sessionStorage.setItem('returningFromStripe', 'Yes');
            // Now notify the user
            handleShowStripeSuccess();
        }

        if (query.get("stripeCanceled")) {
            // Purchase was not complete, set local storage variable
            sessionStorage.setItem('purchaseSuccess', 'No');
            // Set a local storage variable so we know we're returning from Stripe
            // sessionStorage.setItem('returningFromStripe', 'Yes');
            // Now, notify the user
            handleShowStripeCanceled();
        }

    }, []);



    return (
        <div>
            {(state.userID === "None") ?
                <Container className="mb-3" style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                    <h1 className="pt-3 mb-3 h3Plus" style={{ textAlign: 'center' }}>Please <span className="boldWeight">Login</span> Below</h1>
                    <Row>
                        <Col lg={4}>
                            <img src="/wind-turbine-illustration.jpg" alt="Wind turbine illustration" className="windTurbineIllustrationLogin"></img>
                        </Col>
                        <Col lg={8}>

                            <Form style={{ marginTop: "20px", fontSize: 'large' }} >
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address (Use 'guest@guest.com' for the guest login to try out the tool)</Form.Label>
                                    <Form.Control name="email" type="email" placeholder="Enter email" value={userState.email} onChange={myChangeHandler} />
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password (use 'guest_scn' for the guest account)</Form.Label>
                                    <Form.Control name="password" type="password" placeholder="Password" value={userState.password} onChange={myChangeHandler} />
                                    <Link className="small" to="/passwordChange/">Forgot your password?</Link>
                                    {/* <Link style={{ fontSize: '1.2vw' }} to="/passwordChange">Forgot your password?</Link> */}
                                </Form.Group>
                                <button type="button"
                                    disabled={!(userState.email && userState.password)}
                                    onClick={handleLoginFormSubmit}>
                                    Login
                                </button>
                            </Form>
                            <Form.Label>
                                <br></br>
                                Don't have an account yet?  <Link to="/register">Register here.</Link>
                            </Form.Label>

                        </Col>
                    </Row>

                </Container>

                :
                <Dashboard
                    selectionChange={selectionChange}
                    state={state}
                />
            }

            {/* Modal window used for email verification */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Email Confirmation</Modal.Title>
                </Modal.Header>
                {/* Check to see if email was verified  */}
                {(emailVerified === 1) /* email was verified  */
                    ? <Modal.Body>Thank you! Your email has been verified and you can now log in.  Welcome to the Simply Carbon Neutral community!
                    </Modal.Body>
                    : (emailVerified === 2) /* email was already previously verified  */
                        ? <Modal.Body>Thank you! Your email was previously verified and you can log in.  Welcome to the Simply Carbon Neutral community! <br></br>If you are having any issues, please contact support at <a href="mailto:support@simplycarbonneutral.com">support@simplycarbonneutral.com</a>
                        </Modal.Body>
                        /* Verification wasn't found */
                        : <Modal.Body>An error has occurred. Your email was not verified. Please click the link in the email to try again. If the problem persists, please contact support at <a href="mailto:support@simplycarbonneutral.com">support@simplycarbonneutral.com</a>.
                        </Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal window for Stripe Success */}
            <Modal show={showStripeSuccess} onHide={handleCloseStripeSuccess} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Offset Purchase Complete!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Thank you for offsetting your carbon footprint this week!  You will receive a purchase receipt from Stripe.  In one week you'll receive an email with a link to check in again. Your answers will be auto-populated from this week's selections, so it will be quick and easy.<br></br>Thank you for being a part of the Simply Carbon Neutral community!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStripeSuccess}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal window for Stripe Cancel */}
            <Modal show={showStripeCanceled} onHide={handleCloseStripeCanceled} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Offset Purchase Not Complete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your carbon footprint offset purchase was not completed.  Please try again, or contact support at <a href="mailto:support@simplycarbonneutral.com">support@simplycarbonneutral.com</a> if there is an ongoing problem.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStripeCanceled}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>



        </div >
    )
}

export default Login

