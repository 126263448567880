import React, { useEffect } from "react";
import { Container, Row, Col, Table, Accordion, Card } from "react-bootstrap";
// import Wrapper from "../../components/Wrapper"
// import TopCards from "../../components/TopCards"
// import AboutCards from "../../components/AboutCards"
import MissionSection from "../../components/MissionSection";
import ContactSection from "../../components/ContactSection";

function UPCO2_Records({ selectionChange, state }) {
	const callStateFunction = (variable, value) => {
		selectionChange(variable, value);
	};

	useEffect(() => {
		// Set inStripeReturn to false so login and user will be displayed in navbar
		callStateFunction("inStripeReturn", false);
	}, []);

	return (
		<>
			<Container fluid style={{ paddingLeft: "0", paddingRight: "0" }}>
				<Container
					className="mb-3 p-5"
					style={{
						zIndex: "+1",
						position: "relative",
						background: "#FFFFFF",
						boxShadow: "0px 4px 15px 2px rgba(0, 0, 0, 0.25)",
						borderRadius: "10px",
					}}>
					<h1 className="h3Plus" style={{ textAlign: "center" }}>
						Carbon Offset <span className="boldWeight">Transparency</span>
					</h1>
					<Row>
						<Col
							lg={4}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								paddingBottom: "20px",
							}}>
							<img
								src="UPCO2-logodark.svg"
								alt="UPCO2 Symbol"
								width="100%"></img>
						</Col>
						<Col lg={8}>
							<p>
								Because we purchase globally traded and REDD+ certified carbon
								offset tokens (UPCO2), these transactions are fully traceable
								through blockchain. The table below is an ongoing record of all
								Simply Carbon Neutral offset purchase and retirement activities.
								These represent the collective purchases of our Simply Carbon
								Neutral community.
								<br></br>
								<br></br>
								Note, UPCO2 tokens are first purchased on the global
								Environmental Token market. Like any other asset, similar to a
								stock, they can then be held, traded, or sold. Once the owner is
								ready to realize the carbon offset credit and direct funding to
								the project that was issued the tokens via REDD+, the tokens are
								"retired". This closes the token and funds the project. Given
								the digital nature of the token, this entire process is
								traceable through blockchain.
								<br></br>
								<br></br>
								The token purchase takes about one week to clear before they can
								then be retired, as the table below reflects. Also, tokens must
								now be retired in whole number increments. Therefore, Simply
								Carbon Neutral continually retires the full whole number
								quantity remaining in the community purchase pool. Early, small
								purchases are from our beta rollout.
							</p>
						</Col>
					</Row>
				</Container>

				<Container
					className="p-5"
					style={{
						zIndex: "+1",
						position: "relative",
						background: "#FFFFFF",
						boxShadow: "0px 4px 15px 2px rgba(0, 0, 0, 0.25)",
						borderRadius: "10px",
						marginBottom: "-100px",
					}}>
					<Row>
						<Col>
							<h3>UPCO2 Transactions</h3>
							<p>MT = Metric Tons CO2</p>
							<p>Select desired year</p>
							<Accordion>
								<Card>
									{/* <Accordion.Toggle as={Card.Header} eventKey="0"> */}
									<Accordion.Toggle as={Card.Header} eventKey="0">
										2022
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="0">
										<Card.Body id="offsetsTable">
											{/*UPCO2 transaction table */}
											<Table striped bordered hovered>
												<thead>
													<tr>
														<th>Date</th>
														<th>MT</th>
														{/* <th>Retired (MT)</th> */}
														<th>Link</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>03/05</td>
														<td>1.82</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/3c34f509-93dc-4046-a5d4-ca537fa0552e"
																target="_blank">
																Purchase
															</a>
														</td>
													</tr>
													<tr>
														<td>03/12</td>
														<td>1.82</td>
														{/* <td>1.82</td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/9406303b-073e-4f03-b2fa-e89966722d02"
																target="_blank">
																Retire Request
															</a>
														</td>
													</tr>
													<tr>
														<td>03/26</td>
														<td>3.35</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/2aff1ac5-6f8b-4392-a484-1c0aa0ba4309"
																target="_blank">
																Purchase
															</a>
														</td>
													</tr>
													<tr>
														<td>04/02</td>
														<td>3.35</td>
														{/* <td>3.35</td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/4704c4a6-d553-4f16-9380-07e1520753cf"
																target="_blank">
																Retire Request
															</a>
														</td>
													</tr>
													<tr>
														<td>04/09</td>
														<td>1.42</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/2cc1feff-ce80-4afe-bf20-f328102cbde5"
																target="_blank">
																Purchase
															</a>
														</td>
													</tr>
													<tr>
														<td>05/09</td>
														<td>6</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://registry.verra.org/myModule/rpt/myrpt.asp?r=206&h=131992"
																target="_blank">
																Retired
															</a>
														</td>
													</tr>
													<tr>
														<td>07/10</td>
														<td>5.30</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/56280ce1-230f-4c01-8e38-af490d19f5e7"
																target="_blank">
																Purchase
															</a>
														</td>
													</tr>
													<tr>
														<td>09/04</td>
														<td>5.533</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/06ff124d-89ac-4d94-b29d-e80b3512a808"
																target="_blank">
																Purchase
															</a>
														</td>
													</tr>
													<tr>
														<td>09/04</td>
														<td>6</td>
														{/* <td>3.35</td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/f9fffa0b-780f-4cb4-9ebb-d5cb179a0330"
																target="_blank">
																Retire Request
															</a>
														</td>
													</tr>
													<tr>
														<td>11/22</td>
														<td>6</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://registry.verra.org/myModule/rpt/myrpt.asp?r=206&h=141473"
																target="_blank">
																Retired
															</a>
														</td>
													</tr>
													<tr>
														<td>12/05</td>
														<td>8.503</td>
														{/* <td></td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/aefd4608-198c-46ec-8283-203c30160c65"
																target="_blank">
																Purchase
															</a>
														</td>
													</tr>
													<tr>
														<td>12/12</td>
														<td>14</td>
														{/* <td>3.35</td> */}
														<td>
															<a
																href="https://wallet.uphold.com/reserve/transactions/fd1557c0-ccd2-437c-885c-a94669d3742f"
																target="_blank">
																Retire Request
															</a>
														</td>
													</tr>
												</tbody>
											</Table>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</Col>
					</Row>
				</Container>

				<Container fluid style={{ backgroundColor: "#296F50" }}>
					<ContactSection />
				</Container>
			</Container>
		</>
	);
}

export default UPCO2_Records;
