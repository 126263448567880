import React from 'react'
import Dashboard from './Dashboard'

function ReDashboard({ selectionChange, state }) {
    return (
        <>

            <Dashboard
                selectionChange={selectionChange}
                state={state}
            />

        </>

    )
}

export default ReDashboard
