import React from 'react'
import Line from './Line'


function History({ userID }) {
    return (
        <div style={{ textAlign: 'center' }}>
            <Line
                userID={userID}
            />
        </div>

    )
}

export default History
