import React from 'react'
import { Row, Col } from 'react-bootstrap'

export default function ContactSection() {
    return (
        <>
            <div style={{ backgroundColor: '#296F50', color: 'white' }}>
                <Row style={{ marginTop: '-250px', paddingTop: '400px' }}>
                    <Col>
                        <h3 id="contact-section" className="h3Plus pr-5">Contact Us</h3>
                    </Col>
                </Row>
                <Row >
                    <Col lg={12} className="pr-5">
                        <p className="pPlus"><span >We make it easy for users to fund projects, be conscious of their emissions, and move towards living carbon free.</span></p>
                        <p className="pPlus">Simply Carbon Neutral is a small, family business, working to help you better understand your footprint, your choices moving forward, and enable you to fund useful projects at a greatly reduced cost.</p>
                        {/* </Col>
                    <Col lg={4}> */}
                        <Row>
                            <Col>
                                {/* <h3>Call Us</h3>
                                <p><a style={{ color: 'white' }} href="tel:9709807849">+1 (970)-980-7849</a></p> */}
                                <h3 className="pr-5">Email Us</h3>
                                <p><a
                                    style={{ color: "white", textDecoration: "underline" }} href="mailto:support@simplycarbonneutral.com">support@simplycarbonneutral.com</a></p>
                            </Col>
                            <Col>
                                <h3>Location</h3>
                                <p>Fort Collins, CO, USA</p>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col lg={4} id="contact-section" >
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Your Name" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="email" placeholder="Your Email" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control as="textarea" rows={3} placeholder="Message" />
                            </Form.Group>

                            <button type="button">Submit</button>

                        </Form>
                    </Col>
                     */}
                </Row>
            </div>
        </>
    )
}
