import React, { useState } from 'react'
import { Card, ListGroup, ListGroupItem, Form, Modal, Button } from 'react-bootstrap'

// const imageAddress = "https://images.unsplash.com/photo-1586684075772-746ed499fbf1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80";
const imageAddress = "/cars.png";

function TransportationCard({ selectionChange, state, vehicleNumber }) {

    // Set vehicle # variables
    const mpgName = "mpgSelection".concat(vehicleNumber);
    const milesName = "milesSelection".concat(vehicleNumber);



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
        selectionChange("entryChanged", 1);
    };

    return (
        <>
            <Card className="mb-3 mx-auto calculatorCards" style={{ width: '20rem' }}>
                <i id="infoButton" onClick={handleShow} className="far fa-question-circle"></i>
                <Card.Img variant="top" src={imageAddress} className="calculatorCardImage" />
                <Card.Body>
                    <Card.Title>Transportation This Past Week
                    </Card.Title>
                    <Card.Text>
                        {/* <i>Burn any fossil fuels getting around?</i> */}
                        {(vehicleNumber > 0)
                            ?
                            <i>Vehicle # <span className="boldWeight">{vehicleNumber}</span></i>
                            :
                            <i>No personal vehicle miles this week</i>
                        }
                    </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    {(vehicleNumber > 0)
                        ?
                        <ListGroupItem>

                            <Form.Control
                                disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                                value={eval("state.".concat(mpgName))} as="select" custom className="mt-0" id={mpgName} onChange={(e) => callStateFunction(mpgName, e.target.value)}>
                                {/* value={state.mpgSelection} as="select" custom className="mt-0" id="mpgSelection" onChange={(e) => callStateFunction("mpgSelection", e.target.value)}> */}
                                <option value={1}>Average MPG for this vehicle?</option>
                                <option value={2}>Electric vehicle</option>
                                <option value={45}>40+ MPG</option>
                                <option value={35}>30-39 MPG</option>
                                <option value={25}>20-29 MPG (average)</option>
                                <option value={15}>10-19 MPG</option>
                                <option value={5}>1-9 MPG</option>
                            </Form.Control>

                            <Form.Control
                                disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                                value={eval("state.".concat(milesName))} as="select" custom className="mt-3" id={milesName} onChange={(e) => callStateFunction(milesName, e.target.value)}>
                                {/* value={state.milesSelection} as="select" custom className="mt-3" id="milesSelection" onChange={(e) => callStateFunction("milesSelection", e.target.value)}> */}
                                <option value={1}>Miles for this vehicle?</option>
                                <option value={0}>No miles this week</option>
                                <option value={50}>Very Low (50 miles)</option>
                                <option value={125}>Low (125 miles)</option>
                                <option value={300}>Average (300 miles)</option>
                                <option value={400}>Above Average (400+ miles)</option>
                            </Form.Control>

                            {/*<Form.Control
                                disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                                value={state.sharingSelection} as="select" custom className="mt-3" id="sharingSelection" onChange={(e) => callStateFunction("sharingSelection", e.target.value)}>
                                <option value={1}>Ride sharing miles?</option>
                                <option value={0}>0 miles ride sharing</option>
                                <option value={12}>1-25 miles ride sharing</option>
                                <option value={37}>25-50 miles ride sharing</option>
                                <option value={62}>50-75 miles ride sharing</option>
                                <option value={87}>75+ miles ride sharing</option>
                            </Form.Control> */}


                            <Card.Text className="mt-1" style={{ textAlign: "center" }}>
                                <i>{Math.round(eval("state.footprintTransportationCar".concat(vehicleNumber)))} lbs CO<sub>2</sub></i>
                            </Card.Text>

                        </ListGroupItem>

                        :
                        <Card.Text className="mt-1" style={{ textAlign: "center" }}>
                            <i>{Math.round(state.footprintTransportation)} lbs CO<sub>2</sub></i>
                        </Card.Text>
                    }
                </ListGroup >
            </Card >

            {/* Information modal for the Transportation CO2 panel */}
            < Modal show={show} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title>"Transportation" Sources of CO<sub>2</sub></Modal.Title>
                </Modal.Header>
                <Modal.Body>In the Transportation section, we gather the following information:<br></br><br></br>
                    <ul>
                        <li>The approximate MPG fuel efficiency of your vehicle.</li>
                        <li>How many miles you drove the vehicle this past week.</li>
                        <li>For electric vehicles we assume an average equivalent MPG and offset a percentage of the CO<sub>2</sub> if you previously selected that you cover some of your electricity with renewables.</li>

                    </ul>
                    From this information we approximate the gallons of gas that you burned for transportation in the past week.  Given this value and
                    the known quantity of CO<sub>2</sub> per gallon of gas, we arrive at your carbon footprint for transportation for the past week.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >
        </>



    )
}

export default TransportationCard
