import React from 'react'
import { Row, Col } from 'react-bootstrap'

export default function ChallengesSection() {
    return (
        <>
            <Row>
                <Col lg={6} style={{ paddingTop: '150px' }}>
                    <h2><span className="boldWeight">The challenges</span> on the path to carbon neutral.</h2>
                </Col>
                <Col lg={6}>
                </Col>
            </Row>
            <Row className="mt-5">
                {/* <div className="mx-auto"> */}
                <Col lg={6}>
                    <Row>
                        <span style={{ marginRight: '50px' }}>
                            <img style={{ float: 'left', marginRight: '20px' }} alt="Section one icon" src="icons8-level-1-72.png"></img>
                            {/* </Col> */}
                            {/* </div> */}
                            {/* <Col lg={5}> */}
                            <h4 className="boldWeight challengeHeading" style={{ marginTop: '10px' }}>The average American produces 16 tons of harmful emissions per year.</h4>
                            <p className="pPlus grayFont challengeParagraph">With an annual average output of 16 tons of harmful carbon dioxide emissions, each of us need to be aware of our carbon footprint, offset it by purchasing credits to fund climate change programs, and move towards living carbon free.</p>
                        </span>
                    </Row>
                    {/* <Row>
                        <p>With an annual output of 16 million tons of harmful carbon dioxide emissions, Americans need to be aware of their carbon footprint, offset it by purchasing credits to fund climate change programs, and move towards living carbon free.</p>
                    </Row> */}
                </Col>
                {/* <div className="mx-auto"> */}
                <Col lg={6}>
                    <Row>
                        <span style={{ marginRight: '50px' }}>
                            <img style={{ float: 'left', marginRight: '20px' }} alt="Section two icon" src="icons8-circled-2-72.png"></img>
                            {/* </Col> */}
                            {/* </div> */}
                            {/* <Col lg={5}> */}
                            <h4 className="boldWeight challengeHeading" style={{ marginTop: '10px' }}>Combating climate change can be complicated.</h4>
                            <p className="pPlus grayFont challengeParagraph">For the average person striving to understand and offset their carbon footprint, taking action can be difficult. Current applications have extensive calculators that take a lot of time and knowledge to complete.</p>
                        </span>
                    </Row>
                    {/* <Row>
                    </Row> */}
                </Col>
            </Row>
            <Row style={{ paddingBottom: '100px' }}>
                {/* <div className="mx-auto"> */}
                <Col lg={6}>
                    <Row>
                        <span style={{ marginRight: '50px' }}>
                            <img style={{ float: 'left', marginRight: '20px' }} alt="Section three icon" src="icons8-circled-3-72.png"></img>
                            {/* </Col> */}
                            {/* </div> */}
                            {/* <Col lg={5}> */}
                            <h4 className="boldWeight challengeHeading" style={{ marginTop: '10px' }}>Data isn't maintained on a week-to-week basis.</h4>
                            <p className="pPlus grayFont challengeParagraph">The process of tracking the average person's carbon emissions is long and usually calculated over the past year - making data hard to find and resulting in a focus looking back rather than forward.</p>
                        </span>
                    </Row>
                    {/* <Row>
                    </Row> */}
                </Col>
                {/* <div className="mx-auto"> */}
                <Col lg={6}>
                    <Row>
                        <span style={{ marginRight: '50px' }}>
                            <img style={{ float: 'left', marginRight: '20px' }} alt="Section four icon" src="icons8-circled-4-72.png"></img>
                            {/* </Col> */}
                            {/* </div> */}
                            {/* <Col lg={5}> */}
                            <h4 className="boldWeight challengeHeading" style={{ marginTop: '10px' }}>Most models don't directly impact daily behavior.</h4>
                            <p className="pPlus grayFont challengeParagraph" >Many carbon offset services take your data once and then use a monthly subscription model to offset your carbon moving forward.  This is a "set it and forget it" model that doesn't impact behavior to help reduce your footprint in the future.</p>
                        </span>
                    </Row>
                    {/* <Row>
                    </Row> */}
                </Col>
            </Row>
        </>
    )
}
