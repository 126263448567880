import React, { useState, useEffect } from "react";
// import Home from "../Home/Home"
// import Dashboard from "../Dashboard/Dashboard"
// import Wrapper from "../../components/Wrapper"
// import { Link } from 'react-router-dom'
import { Container, Button, Row, Col, Form, Modal } from 'react-bootstrap'

let passwordChangeSent = 0;
let passwordChanged = 0;
let scnPath = '';
let pwChangeCode = '';

let pwMode = "request";

function PasswordChange({ selectionChange, state }) {

    const [userState, setUserState] = useState({
        email: "",
        password: "",
        regEmail: "",
        regPassword: "",
        regPasswordCheck: "",
    });

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    // ****  HERE STARTING TO SWITCH ALARMS TO MODALS *****
    // Variables for displaying the password change request modal
    const [requestShow, setRequestShow] = useState(false);
    const handleRequestClose = () => {
        setRequestShow(false);
        if (passwordChangeSent === 1) {
            window.location.href = "/";
        }
    }
    const handleRequestShow = () => setRequestShow(true);
    //
    // Variables for displaying the password change complete modal
    const [changedShow, setChangedShow] = useState(false);
    const handleChangedClose = () => {
        setChangedShow(false);
        if (passwordChanged === 1) {
            window.location.href = "/login";
        } else {
            window.location.href = "/";
        }
    }
    const handleChangedShow = () => setChangedShow(true);
    //

    const myChangeHandler = (event) => {
        const { name, value } = event.target;
        setUserState({ ...userState, [name]: value })
    };

    function ValidateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return (true)
        }
        alert("You have entered an invalid email address!")
        return (false)
    }

    // Function for user entering email and requesting password change link
    function handlePasswordRequestSubmit(event) {
        event.preventDefault();
        if (ValidateEmail(userState.email)) {

            if (userState.email) {
                passwordChangeSent = 0;
                // Now call the route to set the pwChangeCode and send the email.
                fetch("/api/pwChangeRequest", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    // Added the email confirmation code below
                    body: JSON.stringify({ "email": userState.email }),
                })
                    .then(res => {
                        // console.log("res", res);
                        return res.json();
                    }).then(data => {
                        // console.log(data);
                        // if (data.nModified === 1) {
                        if (data.modifiedCount === 1) {
                            // User found, pwChangeCode set, and email sent.
                            console.log("User Found");
                            passwordChangeSent = 1;
                            handleRequestShow();
                        } else {
                            // User not found
                            console.log("User Not Found");
                            handleRequestShow();
                        }
                    });
            }

        }
    };




    // Function for when user has arrived here with a pwChangeCode, have entered two  passwords to change their password.
    function handlePasswordChangeSubmit(event) {
        event.preventDefault();
        if (userState.regPassword === userState.regPasswordCheck) {
            if (userState.regPassword) {
                passwordChanged = 0;
                // Call route to find this user and set their password
                const changePWRoute = '/api/pwChange/'.concat(pwChangeCode);
                fetch(changePWRoute, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    // Pass the new password
                    body: JSON.stringify({ "password": userState.regPassword }),
                })
                    .then(res => {
                        return res.json();
                    }).then(data => {
                        console.log("password change data: " + JSON.stringify(data));
                        if (!data.error) {
                            // User found and password changed
                            passwordChanged = 1;
                            handleChangedShow();
                        } else {
                            // User not found
                            handleChangedShow();
                        }
                    });
            }
        } else {

        }

    };


    // See if the user is here for the initial request or to set the new PW
    // First, get the app path
    fetch("/api/getPath")
        .then(response => {
            return response.json();
        })
        .then(ReturnData => {
            console.log("ReturnData: " + ReturnData);
            scnPath = ReturnData;
            console.log("scnPath: " + scnPath);
            // Now we know the path for the version we are running.
            // Now we need to see if there is a confirmation code on the current path
            //Store the current path
            const currentPath = window.location.href;
            // Build the path header
            const pathHeader = scnPath.concat('/passwordChange/');
            //Now we need to get any password change code off of the path
            pwChangeCode = currentPath.replace(pathHeader, "");
            console.log("pwChangeCode: " + pwChangeCode);
            if (pwChangeCode !== '') {
                // The pwChangeCode isn't empty, so they are here with a code and need to change their PW.
                // Set pwMode to change to trigger this functionality
                pwMode = "change";
            } // Otherwise set pwMode set to request, because they are here to make the request.
            else {
                pwMode = "request";
            }
            console.log("pwChangeCode: " + pwChangeCode);
            console.log("pathHeader: " + pathHeader);
            console.log("pwMode: " + pwMode);
        })

    useEffect(() => {
        pwMode = "request";
        // The following three lines are just a trick to update the userID state variable to cause the graphs to update after purchase
        let tmpID = state.userID;
        callStateFunction("userID", 1)
        callStateFunction("userID", tmpID)
    }, []);

    return (
        <div>
            {/* For the pwMode variable below - coming in with pwMode=request means that the user is going to enter their email and receive a password change link.
            If pwMode is anything else, that means they are coming in from the link and need to reset their  password. */}

            <Container className="mb-3 pb-5" style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                {(pwMode === "request") ?
                    // Header for entering email and requesting password change link
                    <h1 className="pt-3 mb-3 h3Plus" style={{ textAlign: 'center' }}>Forgot Your Password?</h1>
                    :
                    // Header for setting the new password
                    <h1 className="pt-3 mb-3 h3Plus" style={{ textAlign: 'center' }}>Enter Your New Password</h1>
                }
                <Row>
                    <Col lg={4}>
                        <img src="/wind-turbine-illustration.jpg" alt="Wind turbine illustration" className="windTurbineIllustrationLogin"></img>
                    </Col>
                    <Col lg={8}>
                        {(pwMode === "request") ?
                            // Form for entering email and requesting password change link
                            <Form style={{ marginTop: "20px", fontSize: 'large' }} >
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Enter your email address and we'll send you a link to change your password</Form.Label>
                                    <Form.Control name="email" type="email" placeholder="Enter email" value={userState.email} onChange={myChangeHandler} />
                                </Form.Group>
                                <button type="button"
                                    disabled={!(userState.email)}
                                    // Now call the function to handle the user's request for a password change link 
                                    onClick={handlePasswordRequestSubmit}
                                >
                                    Submit
                                </button>
                            </Form>
                            :
                            // Form for setting the new password
                            <Form style={{ marginTop: "20px", fontSize: 'large' }} >
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control name="regPassword" type="password" placeholder="Password" value={userState.regPassword} onChange={myChangeHandler} />
                                    <Form.Text className="text-muted">
                                        Passwords are encrypted and not stored in clear text.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formBasicPasswordCheck">
                                    <Form.Label>Reenter Password</Form.Label>
                                    <Form.Control name="regPasswordCheck" type="password" placeholder="Password" value={userState.regPasswordCheck} onChange={myChangeHandler} />
                                </Form.Group>
                                <button type="button"
                                    disabled={!(userState.regPassword && userState.regPasswordCheck)}
                                    // Now call the function to handle the user submitting a new password on the form. 
                                    onClick={handlePasswordChangeSubmit}
                                >
                                    Submit
                                </button>
                            </Form>
                        }
                    </Col>
                </Row>

            </Container>

            {/* Modal window used for password REQUEST verification */}
            <Modal show={requestShow} onHide={handleRequestClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Password Change Request</Modal.Title>
                </Modal.Header>
                {/* Check to see if user was found and password change email was sent  */}
                {(passwordChangeSent === 1) /* user found and email sent  */
                    ? <Modal.Body>Thank you! Please check your email for a communication from support@simplycarbonneutral.com.  Click on the link in the email to change your password. Make sure to check any junk or spam folders.
                    </Modal.Body>
                    /* user wasn't found and email wasn't sent */
                    : <Modal.Body>An error has occurred. Your email was not found. Please try again. If the problem persists, please contact support at <a href="mailto:support@simplycarbonneutral.com">support@simplycarbonneutral.com</a>.
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRequestClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Modal window used for password CHANGED verification */}
            <Modal show={changedShow} onHide={handleChangedClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Password Changed</Modal.Title>
                </Modal.Header>
                {/* Check to see if the password was changed   */}
                {(passwordChanged === 1) /* Password was changed  */
                    ? <Modal.Body>Thank you! Your password was changed and you can now login.
                    </Modal.Body>
                    /* user wasn't found and email wasn't sent */
                    : <Modal.Body>An error has occurred. Your email code did not match the database and, therefore, your password was not changed. Please try the email link again. If the problem persists, please contact support at <a href="mailto:support@simplycarbonneutral.com">support@simplycarbonneutral.com</a>.
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleChangedClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>




        </div >
    )
}

export default PasswordChange

