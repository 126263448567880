import React, { useState } from 'react'
import { Card, ListGroup, ListGroupItem, Form, Modal, Button } from 'react-bootstrap'

// const imageAddress = "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80";
const imageAddress = "/shopping.png";

function LifeCard({ selectionChange, state }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
        selectionChange("entryChanged", 1);
    };

    return (
        <>
            <Card className="mb-3 mx-auto calculatorCards" style={{ width: '20rem' }}>
                <i id="infoButton" onClick={handleShow} className="far fa-question-circle"></i>
                <Card.Img variant="top" src={imageAddress} className="calculatorCardImage" />
                <Card.Body>
                    <Card.Title>Life This Past Week
                    </Card.Title>
                    <Card.Text>
                        <i>Your diet, recycling, & non-food purchasing. Don't include second hand purchases</i>
                    </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroupItem>

                        <Form.Control
                            disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                            value={state.dietSelection} as="select" custom className="mt-0" id="dietSelection" onChange={(e) => callStateFunction("dietSelection", e.target.value)}>
                            <option value={1}>What was your diet this week?</option>
                            <option value={2}>I followed a vegan diet</option>
                            <option value={3}>I followed a vegetarian diet</option>
                            <option value={4}>I didn't eat beef</option>
                            <option value={5}>I occasionally ate meat</option>
                            <option value={6}>I was a heavy meat eater </option>
                        </Form.Control>

                        <Form.Control
                            disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)}
                            value={state.recyclingSelection} as="select" custom className="mt-3" id="recyclingSelection" onChange={(e) => callStateFunction("recyclingSelection", e.target.value)}>
                            <option value={1}>How much did you recycle?</option>
                            <option value={2}>I recycled everything possible</option>
                            <option value={3}>I recycled most of the time</option>
                            <option value={4}>I recycled occasionally</option>
                            <option value={5}>I didn't recycle</option>
                        </Form.Control>

                        <Form.Control
                            disabled={(state.state === 1 || state.numPeople === 0 || state.numVehicles === -1)} value={state.consumerSelection} as="select" custom className="mt-3" id="consumerSelection" onChange={(e) => callStateFunction("consumerSelection", e.target.value)}>
                            <option value={1}>Non-Food Purchases per Person?</option>
                            <option value={2}>Very little purchases: $17/pers.</option>
                            <option value={3}>Below ave. purchases: $34/pers.</option>
                            <option value={4}>Average purchases: $68/pers.</option>
                            <option value={5}>Above ave. purchases: $136/pers.</option>
                            <option value={6}>Heavy purchases: $204+/pers.</option>
                        </Form.Control>

                        <Card.Text className="mt-1" style={{ textAlign: "center" }}>
                            <i>{Math.round(state.footprintLife)} lbs CO<sub>2</sub></i>
                        </Card.Text>

                    </ListGroupItem>
                </ListGroup>
            </Card>

            {/* Information modal for the Life CO2 panel */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>"Life" Sources of CO<sub>2</sub></Modal.Title>
                </Modal.Header>
                <Modal.Body>Your Life carbon footprint are the other ways that you produce CO<sub>2</sub> through consumption and waste.  It has three primary elements:<br></br><br></br>
                    <ul>
                        <li>Your diet.  What you eat greatly impacts the CO<sub>2</sub> that is produced during the growing and production of your food.  The amount of beef you eat has
                            the largest impact, while a vegan diet caries the smallest footprint.</li>
                        <li>Waste.  We gather the average CO<sub>2</sub> produced per person per week due to discarding waste/garbage.  We multiply this by the
                            number of people you are entering for, and then decrease it given your level of recycling.</li>
                        <li>Consumerism.  Your non-food purchases generate CO<sub>2</sub> and increases your footprint.  We gather the average CO<sub>2</sub> data per week for
                            various levels of consumerism and adjust your number based on the number of people you are entering for and your level of purchases.
                        </li>
                    </ul>
                    These three items tallied together, represent your "Life" carbon footprint for the previous week.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default LifeCard
