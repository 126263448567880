import React from 'react'
import { Row, Col } from 'react-bootstrap'

export default function TokenSection() {
    return (
        <>
            <Row style={{ paddingTop: '120px', paddingBottom: '120px' }} className="verticalCenter">
                <Col lg={6} className="mb-5">
                    <h2>How does Simply Carbon Neutral <span className="boldWeight">offset </span>my carbon footprint?</h2>
                    <a href="https://universalcarbon.com/" target="_blank" rel="noopener noreferrer">
                        <img src="UPCO2-logolight.svg" alt="UPCO2 Token Logo" width="70%"></img>
                    </a>
                </Col>
                <Col lg={6} className="mb-5">
                    <p className="pPlus">We purchase and retire Universal Carbon Tokens (UPCO2) to offset your weekly carbon footprint.  Each token is tied to a certified REDD+ project preventing rainforest loss or degradation.</p>
                    <br></br>
                    <p className="pPlus">Other carbon offset companies research, select, and work with carbon reduction projects directly. To cover this overhead, your cost is increased by 15-20%.  We reduce your cost and increase your impact by purchasing globally traded carbon tokens directly.</p>
                </Col>
            </Row>
        </>
    )
}
