import React, { useState } from 'react'
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link';

function NavbarMenu({ selectionChange, state }) {

    let [expanded, setExpanded] = useState(false);

    // Modal Adder for Simulation link in navbar
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Navbar collapseOnSelect expand="lg" expanded={expanded}>
            <Navbar.Brand>
                <Link to="/" className="navbarStyle" onClick={() => setExpanded(false)}>
                    <span><Navbar.Brand> <img src="https://www.simplycarbonneutral.com/logo.png" alt="Simply Carbon Neutral logo" style={{ maxHeight: '30px', verticalAlign: 'top', marginRight: '-10px', marginTop: '-8px' }}></img></Navbar.Brand></span>
                    <span style={{ color: '#296F50' }} className="boldWeight"><i>Simply</i> Carbon Neutral</span>
                </Link>
            </Navbar.Brand>
            <Navbar.Brand>
                    <a href="#" onClick={handleShow} className="mx-3">Now Simulation Only</a>
            </Navbar.Brand>
            

            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="justify-content-end navbarStyle" style={{ width: "100%" }}>

                    
                    <Link className="mx-3 navbarStyleLinks" to="/" onClick={() => setExpanded(false)}>Home</Link>

                    <Link className="mx-3 navbarStyleLinks" to="/about#contact-section" onClick={() => setExpanded(false)}>Contact</Link>

                    {/* 
                    <Link className="mx-3 navbarStyleLinks" to="/about" onClick={() => setExpanded(false)}>About</Link>
                    */}

                    <NavDropdown
                        className="navbarStyle mx-3"
                        title="More"
                        style={{ color: '#296F50 !important' }}
                    // menuVariant="dark"
                    >

                        <NavDropdown.Item>
                            <Link className="navbarStyleLinks" to="/about" onClick={() => setExpanded(false)}>About/FAQs</Link>
                        </NavDropdown.Item>

                        <NavDropdown.Divider />

                        <NavDropdown.Item>
                            <Link className="navbarStyleLinks" to="/purchases" onClick={() => setExpanded(false)}>Offset Records</Link>
                        </NavDropdown.Item>

                        <NavDropdown.Divider />

                        <NavDropdown.Item>
                            <Link className="navbarStyleLinks" to="/resources" onClick={() => setExpanded(false)}>Resources</Link>
                        </NavDropdown.Item>

                    </NavDropdown>



                    {
                        (state.inStripeReturn)
                            ?
                            <></>
                            :
                            (state.userID === "None") ? <Link className="mx-3 navbarStyle" to="/login" onClick={() => setExpanded(false)}>
                                <button className="navbarButton" type="button">Login or Guest Access</button>
                            </Link> :
                                <>
                                    <NavDropdown alignRight title={<span className="mx-3 navbarStyle">{state.firstName}</span>}>
                                        <NavDropdown.Item className="navbarDropStyle">
                                            <Link className="navbarStyle" to="/dashboard" onClick={() => setExpanded(false)}>
                                                Dashboard</Link></NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item className="navbarDropStyle">
                                            <Link className="navbarStyle" to="/logout" onClick={() => setExpanded(false)}>
                                                Logout</Link></NavDropdown.Item>
                                    </NavDropdown>
                                </>
                    }
                </Nav>
            </Navbar.Collapse>
                    {/* Information modal for the Life CO2 panel */}
                    <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Now Simulation Only</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Simply Carbon Neutral is now simulation only. Users can create an account, simulate purchases each week (or more often), and track their data. Payment is not collected and carbon offsets are not purchased. The purchase is only simulated. The previous tool was discontinued due to a loss of access to UPCO2 carbon offset tokens. The tool is maintained here for educational purposes: to help the user understand and reduce their carbon footprint.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
        </Navbar>


    )
}

export default NavbarMenu
