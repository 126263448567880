import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'


export default function MissionSection() {
    return (
        <>
            <Row style={{ paddingTop: '150px', paddingBottom: '150px', backgroundColor: 'white' }}>
                <Col lg={6} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h2>Here's a little info about <br></br><span className="boldWeight">Simply Carbon Neutral.</span></h2>
                </Col>
                <Col lg={6}>
                    <div className="leafCar">
                        {/* <img src="leaf-car.png" width="819px"></img> */}
                        <img src="leaf-car.png" alt="Renewables with car" width="90%"></img>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: '-100px', overflow: 'hidden' }}>
                <Col lg={3}>
                    {/* <CardDeck> */}
                    <Card className="mb-3 missionCard">
                        <Card.Img className="mt-5" variant="top" src="gear.png" />
                        <Card.Body>
                            <Card.Title><h4> <span className="boldWeight">Developer</span></h4>
                                {/* <Card.Title><h3>Founder</h3> */}
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">Jon Pointer is a licensed Engineer based in Colorado with 30+ years experience in industrial energy production, renewable energy, and electric vehicles.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className="mb-3 missionCard">
                        <Card.Img className="mt-5" variant="top" src="trophy.png" />
                        <Card.Body>
                            <Card.Title><h4> <span className="boldWeight">Mission</span></h4>
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">Our mission is to enable individuals to easily understand and offset their carbon footprint regularly, increase their knowledge, and live carbon free.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className="mb-3 missionCard">
                        <Card.Img className="mt-5" style={{ marginBottom: '22px' }} variant="top" src="eye.png" />
                        <Card.Body>
                            <Card.Title><h4> <span className="boldWeight">Vision</span></h4>
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">Our vision is each adult in America consistently offsetting their carbon footprint while mindfully reducing their impact over time.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className="mb-3 missionCard">
                        <Card.Img className="mt-5" variant="top" src="book.png" />
                        <Card.Body>
                            <Card.Title><h4> <span className="boldWeight">Philosophy</span></h4>
                            </Card.Title>
                            <Card.Text>
                                <p className="grayFont">The 80/20 rule. We use localized average data and simple questions to calculate your approximate footprint. We make it easy to give and be conscious of your emissions.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


        </>
    )
}
