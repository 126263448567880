import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap'


// import { Line } from '@reactchartjs/react-chart.js'
import { Line } from 'react-chartjs-2';

function LineChart({ userID }) {
    const [data, setData] = useState()
    // const [lineData, setLineData] = useState();

    // const setDataObject = () => {
    //     console.log(lineData)
    // }

    useEffect(() => {
        // console.log(userID)
        // const structuredUserID = `ObjectId("${userID}")`;
        const structuredUserID = userID;
        // console.log("id passed: ", structuredUserID)

        fetch(`/api/offset/${structuredUserID}`)
            .then(response => {
                return response.json();
            })
            .then(ReturnData => {
                console.log("data returned:", ReturnData)

                let lineDataPoints = [];
                let lineLabels = [];

                for (let i = 0; i < ReturnData.length; i++) {
                    // console.log(data[i])
                    lineDataPoints.push({ "x": ReturnData[i].date, "y": ReturnData[i].footprint })
                    lineLabels.push(new Date(ReturnData[i].date).toLocaleDateString())
                }

                console.log("lineDataPoints: ", lineDataPoints);
                console.log("lineLabels: ", lineLabels);

                const plotData = {
                    labels: lineLabels,
                    datasets: [
                        {
                            label: "Carbon Footprint (lbs)",
                            data: lineDataPoints,
                            fill: false,
                            backgroundColor: 'rgb(31, 130, 49)',
                            borderColor: 'rgba(31, 130, 49, 0.3)',
                        },
                    ],
                }
                setData(plotData)
                // console.log("lineDataPoints: ", lineDataPoints);
            });

    }, [userID]);

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }

    return (
        <Container>
            {/* < div className='header' > */}
            <h2 className='title pt-3 mb-3'>Your Carbon Footprint History</h2>
            {/* </div > */}
            { data && <Line data={data} options={options} />
            }
        </Container>
    )
}

export default LineChart
