import React, { useState, useEffect } from "react";
// import Home from "../Home/Home"
import Dashboard from "../Dashboard/Dashboard"
// import Wrapper from "../../components/Wrapper"
import { Link } from 'react-router-dom'
import { Container, Button, Row, Col, Form, Modal } from 'react-bootstrap'

let emailVerified = 0;
let scnPath = '';
let purchaseSuccess = "";

let currentState = {};

function StripeReturn({ selectionChange, state }) {

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    const toLogin = () => {
        alert("In toLogin")
    }


    // Post route to save this offset to the database and update user date
    // First, save the offset 
    const saveFunction = (passState) => {

        // Create a new object that has passState with offsetPurchased: false added

        const addOffsetPurchased = {
            offsetPurchased: false
        };
        const newPassState = Object.assign(passState, addOffsetPurchased);

        // Now call the offset route

        fetch("/api/offset", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newPassState),
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data);
                // Set that the purchaseSaveComplete variable
                sessionStorage.setItem('purchaseSaveComplete', 'Yes');
                // The following three lines are just a trick to update the userID state variable to cause the graphs to update after purchase
                let tmpID = state.userID;
                callStateFunction("userID", 1)
                callStateFunction("userID", tmpID)
            });
        // Next, set the dateOfLastPurchase for the user
        fetch("/api/dateOfLastPurchase", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(passState),
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data);
            });
    }




    // Get the parameters off of the current route
    const query = new URLSearchParams(window.location.search);




    useEffect(() => {

        // Set inStripeReturn so that Login isn't displayed in navbar
        callStateFunction("inStripeReturn", true);

        // Returning from Stripe purchase or cancellation.  Need to reset everything.

        // Get the parameters off of the current route
        // const query = new URLSearchParams(window.location.search);

        // If there are search parameters (confirming that we are returning from Stripe), then we need to reset by reading in the constants array (from the database) and all of the state variables (from session storage).
        if ((query.get("stripeSuccess")) || ("stripeCanceled")) {
            // First, read in the constants
            fetch("/api/constants")
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    // Now set the state variable for constants.
                    callStateFunction("constants", data);

                    // Next, we set all of the state variable from session storage

                    // If there is a current working state in local storage, replace the card values with that
                    if ((sessionStorage.getItem('currentState')).length > 1) {
                        // Get the current state from session storage
                        currentState = JSON.parse(sessionStorage.getItem('currentState'));
                        // Now set all of the cards 
                        if (currentState.state !== "1") {
                            callStateFunction("state", currentState.state);
                            callStateFunction("numPeople", currentState.numPeople);
                            callStateFunction("numVehicles", currentState.numVehicles);
                            callStateFunction("homeSelection", currentState.homeSelection);
                            callStateFunction("electricitySelection", currentState.electricitySelection);
                            callStateFunction("heatingSelection", currentState.heatingSelection);
                            callStateFunction("mpgSelection1", currentState.mpgSelection1);
                            callStateFunction("milesSelection1", currentState.milesSelection1);
                            callStateFunction("mpgSelection2", currentState.mpgSelection2);
                            callStateFunction("milesSelection2", currentState.milesSelection2);
                            callStateFunction("mpgSelection3", currentState.mpgSelection3);
                            callStateFunction("milesSelection3", currentState.milesSelection3);
                            callStateFunction("mpgSelection4", currentState.mpgSelection4);
                            callStateFunction("milesSelection4", currentState.milesSelection4);
                            callStateFunction("dietSelection", currentState.dietSelection);
                            callStateFunction("recyclingSelection", currentState.recyclingSelection);
                            callStateFunction("consumerSelection", currentState.consumerSelection);
                        }
                    }
                    // Now, take action on stripeSuccess or stripeCanceled parameters
                    if (query.get("stripeSuccess")) {
                        // Purchase was complete, set local storage variable
                        sessionStorage.setItem('purchaseSuccess', 'Yes');
                        purchaseSuccess = "Yes";

                        // Now, see if they have made a successful purchase, but it hasn't been saved yet.

                        if ((sessionStorage.getItem('purchaseSuccess') === 'Yes') && (sessionStorage.getItem('purchaseSaveComplete') === 'No')) {
                            // They have purchased, but it hasn't been saved.
                            // So, read the state from local storage and save the state to the DB
                            let currentState = JSON.parse(sessionStorage.getItem('currentState'));
                            saveFunction(currentState);
                        }


                    }

                    if (query.get("stripeCanceled")) {
                        // Purchase was not complete, set local storage variable
                        sessionStorage.setItem('purchaseSuccess', 'No');
                        purchaseSuccess = "No";
                    }
                });
        }
    }, []);

    return (
        <>
            <Container fluid style={{ paddingLeft: '0', paddingRight: '0' }}>

                {/* Determine message to display depending on purchase success variable set above */}

                {/* {(purchaseSuccess === "Yes") ? */}
                {((query.get("stripeSuccess")) && (sessionStorage.getItem('purchaseSaveComplete') === 'Yes')) ?

                    <Container className="mb-3 p-5" style={{ zIndex: '+1', position: 'relative', background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>

                        <Row>
                            <Col lg={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '20px' }}>
                                <img src="vecteezy_thank-you-hand-lettering-black-ink-brush-calligraphy_6033257.jpg" alt="Green Leaf with city illustration" width="100%"></img>
                                {/* <img src="green-leaf-transparent.png" alt="Green Leaf with city illustration" width="100%"></img> */}
                            </Col>
                            <Col lg={8}>
                                {/* <h4 className="boldWeight" style={{ textAlign: 'center' }}>Thank you for offsetting your carbon footprint this week!</h4> */}
                                {/* <h2 style={{ textAlign: 'center' }}>Offset Purchase <span className="boldWeight"> Complete!</span></h2> */}
                                <h2 style={{ textAlign: 'center' }}><span className="boldWeight">Thank you </span>for (simulating) offsetting your CO<sub>2</sub> footprint this week!</h2>
                                {/* <br></br> */}
                                <h3 className="grayFont">You've taken a great step towards understanding your footprint and moving towards living carbon free.</h3>
                                {/* <p className="pPlus grayFont">
                                    What happens next?
                                    <ul>
                                        <li>
                                            Stripe will email you a receipt for your purchase.
                                        </li>
                                        <li>In one week you'll receive a note from us to check in again. Autofill responses will make it quick and easy!
                                        </li>
                                    </ul>
                                </p> */}
                                <h4 className="pPlus grayFont">See you next week! If you'd like, click below to return to the Dashboard and review your progress.</h4>
                                {/* <h4 className="pPlus grayFont">See you next week! If you'd like, return to the Dashboard to review your progress and get tips on what you can do next.</h4> */}
                                <br></br>
                                <h4 style={{ textAlign: 'center' }}>
                                    <Link to="/login">
                                        <span className="mt-3">
                                            <button type="button" onClick="toLogin">Return to Dashboard</button>
                                        </span>
                                    </Link>
                                </h4>
                            </Col>
                        </Row>
                    </Container>

                    :

                    ((query.get("stripeSuccess")) && (sessionStorage.getItem('purchaseSaveComplete') === 'No')) ?

                        <Container className="mb-3 p-5" style={{ zIndex: '+1', position: 'relative', background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                            <h2 style={{ textAlign: 'center' }}>Offset Purchase <span className="boldWeight"> Error</span></h2>
                            <Row>
                                <Col lg={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '20px' }}>
                                    <img src="green-leaf-transparent.png" alt="Green Leaf with city illustration" width="100%"></img>
                                </Col>
                                <Col lg={8}>
                                    <h4>It appears that your Stripe purchase was complete, but your offset was not entered into the database.  Please return to the Dashboard and try again. If you end up with multiple Stripe purchase receipts in your email, please contact support at <a href="mailto:support@simplycarbonneutral.com"><span style={{ wordWrap: "break-word" }}>support@simplycarbonneutral.com</span></a> for reimbursement.</h4>
                                    <h4 style={{ textAlign: 'center' }}>
                                        <Link to="/login">
                                            <span className="mt-3">
                                                <button type="button">Return to Dashboard</button>
                                            </span>
                                        </Link>
                                    </h4>
                                </Col>
                            </Row>


                        </Container>
                        :
                        <Container className="mb-3 p-5" style={{ zIndex: '+1', position: 'relative', background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                            <h2 style={{ textAlign: 'center' }}>Offset Purchase <span className="boldWeight"> Not Complete</span></h2>
                            <Row>
                                <Col lg={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '20px' }}>
                                    <img src="green-leaf-transparent.png" alt="Green Leaf with city illustration" width="100%"></img>
                                </Col>
                                <Col lg={8}>
                                    <h4>Your carbon footprint offset purchase was not completed.  Please try again, or contact support at <a href="mailto:support@simplycarbonneutral.com"><span style={{ wordWrap: "break-word" }}>support@simplycarbonneutral.com</span></a> if there is an ongoing problem.</h4>
                                    <h4 style={{ textAlign: 'center' }}>
                                        <Link to="/login">
                                            <span className="mt-3">
                                                <button type="button">Return to Dashboard</button>
                                            </span>
                                        </Link>
                                    </h4>
                                </Col>
                            </Row>


                        </Container>
                }
            </Container>

        </>
    )
}

export default StripeReturn

