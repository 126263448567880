import React, { useEffect } from 'react'
import { Carousel, Card, Row, Col, Container } from 'react-bootstrap'
import TransportationCard from './TransportationCard'
import HomeCard from './HomeCard'
import PurchaseCard from './PurchaseCard'
import LifeCard from './LifeCard'
import UserData from './UserData'

// largeScreen will be false if screen is < 550px, then Carousel controls will be disabled below.
// This is to turn off arrows on Carousel on cell phones where they overlap the form and get in the way of making selections.
// Instead, on the phone, the user just swipes for the next card.
const largeScreen = window.matchMedia("(min-width: 550px)").matches;

let vehicleMap = [];

let purchaseSaveComplete = "";

function Calculator({ selectionChange, state }) {

    // Read the purchaseSaveComplete variable so that the button choice can use it below.
    purchaseSaveComplete = sessionStorage.getItem('purchaseSaveComplete');

    useEffect(() => {
        // Read the purchaseSaveComplete variable so that the button choice can use it below.
        purchaseSaveComplete = sessionStorage.getItem('purchaseSaveComplete');
    }, []);

    if ((state.numVehicles >= 1) && (state.numVehicles <= 4)) {
        vehicleMap = Array.from({ length: state.numVehicles }, (_, i) => i + 1)
    }
    else {
        vehicleMap = [];
    }

    return (

        //  TEST TEXT

        // <>
        < Container style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }
        }>

            {/* Testing mapping array of number of vehicles to TransportationCard  */}
            {/* Build array here base on number of vehicles entry  */}

            <Row>
                <Col id="carouselCardsSection">
                    <h3 className="boldWeight calculatorTitle mt-3">Calculate and Offset Your Carbon Footprint for the Week</h3>






                    <Carousel
                        wrap={false}
                        controls={largeScreen}
                        interval={null} style={{ paddingBottom: '50px' }}
                        // activeIndex={state.activeCarouselIndex}

                        nextIcon={<span aria-hidden="false" className="carousel-control-next-icon carousel-dark"
                            indicators={true} indicatorLabels={["1", "2", "3", "4"]}
                        />}>

                        <Carousel.Item className="mt-3">




                            <Card.Body style={{ textAlign: 'center', marginTop: '-40px' }}>
                                {/* Check to see which button to display. */}
                                {(purchaseSaveComplete === 'Yes')
                                    ?
                                    /* They have already purchased, so grey out and give note that they are done. */
                                    // <button type="button" disabled={true}>Well Done!<br></br>Your offset is complete for this week!</button>
                                    <></>
                                    :
                                    /* Purchase isn't done, so check to see if all cards are filled out */
                                    ((state.state !== 1) && (state.numPeople !== 0) && (state.numVehicles !== -1) &&
                                        (state.homeSelection !== 1) && (state.electricitySelection !== 1) && (state.heatingSelection !== 1) &&
                                        (state.dietSelection !== 1) && (state.recyclingSelection !== 1) && (state.consumerSelection !== 1) &&
                                        // Leave the button on only if no entries have changed - if they haven't changed from last week. 
                                        (state.entryChanged == 0) &&
                                        // Now the vehicles check 
                                        // Vehicle 1 settings are entered, or there is < 1 vehicle
                                        (((state.mpgSelection1 !== 1) && (state.milesSelection1 !== 1)) || (state.numVehicles < 1)) &&
                                        // Vehicle 2 settings are entered, or there are < 2 vehicles
                                        (((state.mpgSelection2 !== 1) && (state.milesSelection2 !== 1)) || (state.numVehicles < 2)) &&
                                        // Vehicle 3 settings are entered, or there are < 3 vehicles
                                        (((state.mpgSelection3 !== 1) && (state.milesSelection3 !== 1)) || (state.numVehicles < 3)) &&
                                        // Vehicle 4 settings are entered, or there are < 4 vehicles
                                        (((state.mpgSelection4 !== 1) && (state.milesSelection4 !== 1)) || (state.numVehicles < 4))
                                    )
                                        ?
                                        /* All filled out, so trigger Stripe purchase */
                                        /* Stripe Button */
                                        <form action="/api/create-checkout-session"
                                            method="POST">
                                            {/* Pass state.cost to Stripe */}
                                            <select id="price" name="price" style={{ display: 'none' }}>
                                                <option value={state.cost}></option>
                                            </select>
                                            {/* Pass state.footprint to Stripe */}
                                            <select id="footprint" name="footprint" style={{ display: 'none' }}>
                                                <option value={state.footprint}></option>
                                            </select>
                                            {/* <p>Or click below to purchase your offset using last week's entries.</p> */}
                                            {/* <button type="submit" className="p">
                                                Quick Purchase: {"$" + (state.cost).toFixed(2) + " for " + Math.round(state.footprint) + " lbs CO"}<sub>2</sub>
                                            </button> */}
                                        </form>
                                        :
                                        /* Not filled out, so display nothing */
                                        // <button type="button" disabled={true}><i>Purchase Offset!</i><br></br>Please answer all question cards.</button>
                                        <></>
                                }
                            </Card.Body>









                            <UserData
                                selectionChange={selectionChange}
                                state={state}
                            />
                        </Carousel.Item>

                        <Carousel.Item className="mt-3">
                            <HomeCard
                                selectionChange={selectionChange}
                                state={state}
                            />
                        </Carousel.Item>

                        {/* Check to see if number of vehicles is 0 (none) or 10 (bus) and call transportation card for each  */}

                        {/* TBD  */}

                        {/* Now, if number of vehicles is 1-4, Map the array of number of vehicles into the display of transportation cards  */}

                        {/* Need to build the number of vehicles array above  */}

                        {/* {((state.numVehicles >= 1) && (state.numVehicles <= 4)) ? */}
                        {/* <> */}
                        {
                            vehicleMap.map((value, index) => {
                                return <Carousel.Item className="mt-3">
                                    <TransportationCard
                                        selectionChange={selectionChange}
                                        state={state}
                                        vehicleNumber={value}
                                    />
                                </Carousel.Item>
                            })
                        }
                        {/* </> */}
                        {/* : */}
                        {/* <></> */}
                        {/* } */}


                        <Carousel.Item className="mt-3">
                            <LifeCard
                                selectionChange={selectionChange}
                                state={state}
                            />
                        </Carousel.Item>

                        <Carousel.Item className="mt-3">
                            <PurchaseCard
                                selectionChange={selectionChange}
                                state={state}
                            />
                        </Carousel.Item>

                    </Carousel>

                </Col>
            </Row>
        </Container >



    )
}

export default Calculator
