import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { Line, Doughnut } from 'react-chartjs-2';

// largeScreen will be false if screen is < 550px.  
// myMx is set to just grayFont, assuming we are on a small screen.
// If largeScreen is true, then mx-1 is added.
// In this way we don't have the side margin on the small screen. 
const largeScreen = window.matchMedia("(min-width: 550px)").matches;
let myMx = "";
if (largeScreen) {
    myMx = "ml-1 mr-1 grayFont";
};
let pieTotal = 0;

let totalFootprintToDate = 0;

let currentState = {};

function LineChart({ selectionChange, state, userID }) {
    const [totalFootprintData, totalFootprintSetData] = useState()
    const [homeFootprintData, homeFootprintSetData] = useState()
    const [transportationFootprintData, transportationFootprintSetData] = useState()
    const [lifeFootprintData, lifeFootprintSetData] = useState()
    const [pieData, pieSetData] = useState()

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    useEffect(() => {
        // const structuredUserID = `ObjectId("${userID}")`;
        const structuredUserID = userID;
        console.log("id passed: ", structuredUserID)

        fetch(`/api/offset/${structuredUserID}`)
            .then(response => {
                return response.json();
            })
            .then(ReturnData => {

                console.log("data returned:", ReturnData)
                console.log("ReturnDataLength: ", ReturnData.length)

                let lineLabels = [];
                // Total footprint data
                let totalFootprintDataPoints = [];
                // Home footprint data
                let homeFootprintDataPoints = [];
                // Transportation footprint data
                let transportationFootprintDataPoints = [];
                // Life footprint data
                let lifeFootprintDataPoints = [];

                for (let i = 0; i < ReturnData.length; i++) {
                    // X Axis date data
                    lineLabels.push(new Date(ReturnData[i].date).toLocaleDateString())
                    // Y Axis data defined above
                    totalFootprintDataPoints.push({ "x": ReturnData[i].date, "y": Math.round(ReturnData[i].footprint) })
                    homeFootprintDataPoints.push({ "x": ReturnData[i].date, "y": Math.round(ReturnData[i].footprintHome) })
                    transportationFootprintDataPoints.push({ "x": ReturnData[i].date, "y": Math.round(ReturnData[i].footprintTransportation) })
                    lifeFootprintDataPoints.push({ "x": ReturnData[i].date, "y": Math.round(ReturnData[i].footprintLife) })

                    // Update the user's lifetime total
                    totalFootprintToDate = totalFootprintToDate + ReturnData[i].footprint

                    // Assign last offset data entries to card selections - so user starts with their last selections
                    // Unless they are currently working on a state 
                    // if ((i === (ReturnData.length - 1)) && ((sessionStorage.getItem('currentState')).length <= 1)) {
                    if (i === (ReturnData.length - 1)) {

                        // Only set state variables to the DB values if:
                        // It's the first time after logging in (firstCardSetComplete !== Yes)
                        // or purchase is complete and has been saved (purchaseSaveComplete = Yes)
                        // if ((sessionStorage.getItem('firstCardSetComplete') !== "Yes") || (sessionStorage.getItem('purchaseSaveComplete') === "Yes")) {
                        if ((sessionStorage.getItem('firstCardSetComplete') !== "Yes") && ((sessionStorage.getItem('currentState')).length <= 1)) {
                            callStateFunction("state", ReturnData[i].state);
                            callStateFunction("numPeople", ReturnData[i].numPeople);
                            callStateFunction("numVehicles", ReturnData[i].numVehicles);
                            callStateFunction("homeSelection", ReturnData[i].homeSelection);
                            callStateFunction("electricitySelection", ReturnData[i].electricitySelection);
                            callStateFunction("heatingSelection", ReturnData[i].heatingSelection);
                            callStateFunction("mpgSelection1", ReturnData[i].mpgSelection1);
                            callStateFunction("milesSelection1", ReturnData[i].milesSelection1);
                            callStateFunction("mpgSelection2", ReturnData[i].mpgSelection2);
                            callStateFunction("milesSelection2", ReturnData[i].milesSelection2);
                            callStateFunction("mpgSelection3", ReturnData[i].mpgSelection3);
                            callStateFunction("milesSelection3", ReturnData[i].milesSelection3);
                            callStateFunction("mpgSelection4", ReturnData[i].mpgSelection4);
                            callStateFunction("milesSelection4", ReturnData[i].milesSelection4);
                            callStateFunction("dietSelection", ReturnData[i].dietSelection);
                            callStateFunction("recyclingSelection", ReturnData[i].recyclingSelection);
                            callStateFunction("consumerSelection", ReturnData[i].consumerSelection);
                            // Now set that the first save has been done
                            sessionStorage.setItem('firstCardSetComplete', 'Yes');
                        }

                        // Determine date of last purchase and if it was within the last 6 days
                        // If within last 6 days, set purchaseSaveComplete to Yes

                        // First, convert the last purchase date to the zero hour of the local date
                        // so it doesn't matter when during the day they did their purchase.
                        let lastPurchaseDate = new Date(new Date(ReturnData[i].date).toLocaleDateString());
                        // Now get today's date
                        let todaysDate = new Date();

                        // Now check to see if it's been 7 or more days since their last offset.
                        // if ((todaysDate - lastPurchaseDate) / (1000 * 60 * 60 * 24) < 7) {
                        // Modify this if statement so the system never thinks they are up to date to allow for continuous "purchases"
                        if (!true) {  // It will never do this - will always jump to the else
                            // It's been less than 7 days, so they are up to date.
                            sessionStorage.setItem('purchaseSaveComplete', 'Yes');
                            sessionStorage.setItem('purchaseSuccess', 'Yes');
                        } else {
                            // 7 or more days, so not up to date.  Set variables to allow them to purchase
                            // console.log("Changing to No, today, last: ", todaysDate, lastPurchaseDate);
                            sessionStorage.setItem('purchaseSaveComplete', 'No');
                            sessionStorage.setItem('purchaseSuccess', 'No');
                        }
                    }
                }

                // Update and format the totalFootprintToDate total
                totalFootprintToDate = (totalFootprintToDate / 2205).toFixed(2)

                // If there is a current working state in local storage, replace the card values with that
                if ((sessionStorage.getItem('currentState')).length > 1) {
                    currentState = JSON.parse(sessionStorage.getItem('currentState'));
                    console.log("currentState: ", currentState)
                    // Now set all of the cards - NOTE - should rewrite this and the one above as a function call
                    if (currentState.state !== "1") {
                        callStateFunction("state", currentState.state);
                        callStateFunction("numPeople", currentState.numPeople);
                        callStateFunction("numVehicles", currentState.numVehicles);
                        callStateFunction("homeSelection", currentState.homeSelection);
                        callStateFunction("electricitySelection", currentState.electricitySelection);
                        callStateFunction("heatingSelection", currentState.heatingSelection);
                        callStateFunction("mpgSelection1", currentState.mpgSelection1);
                        callStateFunction("milesSelection1", currentState.milesSelection1);
                        callStateFunction("mpgSelection2", currentState.mpgSelection2);
                        callStateFunction("milesSelection2", currentState.milesSelection2);
                        callStateFunction("mpgSelection3", currentState.mpgSelection3);
                        callStateFunction("milesSelection3", currentState.milesSelection3);
                        callStateFunction("mpgSelection4", currentState.mpgSelection4);
                        callStateFunction("milesSelection4", currentState.milesSelection4);
                        callStateFunction("dietSelection", currentState.dietSelection);
                        callStateFunction("recyclingSelection", currentState.recyclingSelection);
                        callStateFunction("consumerSelection", currentState.consumerSelection);
                    }
                }

                if (ReturnData.length > 0) {

                    // Pie chart data
                    pieTotal = Math.round(ReturnData[ReturnData.length - 1].footprint);
                    const piePlotData = {
                        labels: ["Home", "Transportation", "Life"],

                        datasets: [
                            {
                                // label: '# of Votes',
                                // data: [12, 19, 3, 5, 2, 3],
                                data: [Math.round(ReturnData[ReturnData.length - 1].footprintHome), Math.round(ReturnData[ReturnData.length - 1].footprintTransportation), Math.round(ReturnData[ReturnData.length - 1].footprintLife)],
                                fill: true,
                                // width: '10px',
                                backgroundColor: [
                                    'rgb(31, 130, 49)',
                                    'rgba(185, 185, 185)',
                                    'rgba(223, 142, 48)',
                                ],
                                borderColor: [
                                    'rgb(31, 130, 49, 1)',
                                    'rgba(185, 185, 185, 1)',
                                    'rgba(223, 142, 48, 1)',
                                ],
                                borderWidth: 1,
                            },
                        ],
                    };

                    const totalFootprintPlotData = {
                        labels: lineLabels,
                        datasets: [
                            {
                                // label: "Carbon Footprint (lbs)",
                                data: totalFootprintDataPoints,
                                fill: false,
                                backgroundColor: 'rgb(31, 130, 49)',
                                borderColor: 'rgba(31, 130, 49)',
                            },
                        ],
                    }
                    const homeFootprintPlotData = {
                        labels: lineLabels,
                        datasets: [
                            {
                                // label: "Carbon Footprint (lbs)",
                                data: homeFootprintDataPoints,
                                fill: false,
                                backgroundColor: 'rgb(31, 130, 49)',
                                borderColor: 'rgba(31, 130, 49)',
                            },
                        ],
                    }
                    const transportationFootprintPlotData = {
                        labels: lineLabels,
                        datasets: [
                            {
                                // label: "Carbon Footprint (lbs)",
                                data: transportationFootprintDataPoints,
                                fill: false,
                                backgroundColor: 'rgb(31, 130, 49)',
                                borderColor: 'rgba(31, 130, 49)',
                            },
                        ],
                    }
                    const lifeFootprintPlotData = {
                        labels: lineLabels,
                        datasets: [
                            {
                                // label: "Carbon Footprint (lbs)",
                                data: lifeFootprintDataPoints,
                                fill: false,
                                backgroundColor: 'rgb(31, 130, 49)',
                                borderColor: 'rgba(31, 130, 49)',
                            },
                        ],
                    }

                    // Display graphs
                    pieSetData(piePlotData)
                    totalFootprintSetData(totalFootprintPlotData)
                    homeFootprintSetData(homeFootprintPlotData)
                    transportationFootprintSetData(transportationFootprintPlotData)
                    lifeFootprintSetData(lifeFootprintPlotData)

                }

                else {
                    // There was no data return (new user), so set the purchase variables in local storage
                    // and set all of the plots to empty arrays 
                    sessionStorage.setItem('purchaseSaveComplete', 'No');
                    sessionStorage.setItem('purchaseSuccess', 'No');
                    pieSetData([])
                    totalFootprintSetData([])
                    homeFootprintSetData([])
                    transportationFootprintSetData([])
                    lifeFootprintSetData([])

                }



            });

    }, [userID]);

    const options = {
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Pounds CO2',
                    }
                },
            ],
        },
    }
    const pieOptions = {
        legend: {
            display: true,
            position: 'right',
        },
        cutoutPercentage: 50,
        // hoverOffset: '15px',

    }

    return (
        <>
            <Row className={myMx}>
                {/* <Row className="ml-1 mr-1 grayFont"> */}
                <Col lg={4} className="mt-3">
                    <Container className="pb-2" style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                        {/* < div className='header' > */}
                        <h4 id="doughnutGraph" className='title pt-3 mb-3'>Most Recent Offset ({pieTotal} lbs.)</h4>
                        <Doughnut data={pieData} options={pieOptions} />
                        <br></br>
                        {/* <p><span className="boldWeight">Coming Soon!</span> Tips and suggestions based on your footprint data!</p> */}
                    </Container>
                </Col>
                <Col lg={8} className="mt-3">
                    <Container style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                        {/* < div className='header' > */}
                        <h4 className='title pt-3 mb-3'>Weekly Totals ({totalFootprintToDate} Metric Tons to Date)</h4>
                        {/* </div > */}
                        {totalFootprintData && <Line data={totalFootprintData} options={options} />
                        }
                    </Container>
                </Col>
            </Row>
            <Row className={myMx}>
                <Col lg={4} className="mt-3">
                    <Container style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                        {/* < div className='header' > */}
                        <h4 className='title pt-3 mb-3'>Home</h4>
                        {/* </div > */}
                        {homeFootprintData && <Line data={homeFootprintData} options={options} />
                        }
                    </Container>
                </Col>
                <Col lg={4} className="mt-3">
                    <Container style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                        {/* < div className='header' > */}
                        <h4 className='title pt-3 mb-3'>Transportation</h4>
                        {/* </div > */}
                        {transportationFootprintData && <Line data={transportationFootprintData} options={options} />
                        }
                    </Container>
                </Col>
                <Col lg={4} className="mt-3 pb-3">
                    <Container style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                        {/* < div className='header' > */}
                        <h4 className='title pt-3 mb-3'>Life</h4>
                        {/* </div > */}
                        {lifeFootprintData && <Line data={lifeFootprintData} options={options} />
                        }
                    </Container>
                </Col>
            </Row>
        </>
    )
}

export default LineChart
