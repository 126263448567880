import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'



export default function CalculateIntroSection() {
    return (
        <>
            <Row >
                {/* <Col lg={6} className="calculateIntroLeft" >
                    <div style={{ position: 'relative' }}>
                        <img src="calculate-background.png" width="100%"></img>
                        <h2 style={{ position: 'absolute', top: '40%', left: '20%', textAlign: 'center' }}><b>Calculate</b> your<br></br> Carbon Footprint. <br></br><br></br><button type="button">Get Started</button></h2>
                    </div>
                </Col> */}
                {/* <Col lg={6} className="calculateIntroLeft" style={{ backgroundImage: 'url("calculate-background.png")', height: '1000px' }}> */}
                <Col lg={6} className="calculateIntroLeft">
                    <span class="helper"></span>
                    <img src="calculate-background.png" alt="Background" style={{ width: '100%', height: '100%', backgroundSize: 'cover' }}></img>
                    <div className="centerTextInImage">
                        <h2><span className="boldWeight">Calculate</span> your Carbon Footprint.</h2>
                        <Link to="/login">
                            <button className="pageButton" type="button">Get Started</button>
                        </Link>
                    </div>

                </Col>
                <Col lg={6} className="calculateIntroRight">
                    <div className="mx-auto">
                        <h2 className="mt-3 h3Plus"><span >A few simple questions.</span></h2>
                        {/* <br></br> */}

                        <div class="card mb-3 mx-auto">
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src="house.png" class="img-fluid rounded-start" alt="..."></img>
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h3 class="card-title ">Home</h3>
                                        <p class="card-text" className="calcRightCardText">How big is your home and do you use any renewable energy?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-3 mx-auto">
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src="cars.png" class="img-fluid rounded-start" alt="..."></img>
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h3 class="card-title ">Transportation</h3>
                                        <p class="card-text" className="calcRightCardText">Do you burn any fossil fuels getting around?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-3 mx-auto">
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src="shopping.png" class="img-fluid rounded-start" alt="..."></img>
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h3 class="card-title ">Life</h3>
                                        <p class="card-text" className="calcRightCardText">What is your diet, do you recycle, and are you a big consumer?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
