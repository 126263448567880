import React from 'react'
import UserCO2Data from '../../components/UserCO2Data'
import Calculator from '../../components/Calculator'

// largeScreen will be false if screen is < 550px.  
// backgroundTopPadding is set to 0px, assuming we are on a small screen.
// If largeScreen is true, then the padding is changed to 20px. 
const largeScreen = window.matchMedia("(min-width: 550px)").matches;
let backgroundTopPadding = {
    paddingTop: '0px'
};
if (largeScreen) {
    backgroundTopPadding = {
        paddingTop: '20px'
    };
}

function Dashboard({ selectionChange, state }) {
    return (
        <>
            <div className="calculatorCardsBackground" style={backgroundTopPadding}>
                <Calculator
                    selectionChange={selectionChange}
                    state={state}
                />
                <UserCO2Data
                    selectionChange={selectionChange}
                    state={state}
                    userID={state.userID}
                />
            </div>
        </>

    )
}

export default Dashboard
