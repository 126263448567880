import React from 'react'
import { Row, Col } from 'react-bootstrap'

export default function OffsetsSection() {
    return (
        <>
            <Row style={{ paddingTop: '120px', paddingBottom: '120px' }} className="verticalCenter">
                <Col lg={6} className="mb-5">
                    <h2>I read <span className="boldWeight">carbon offsets </span>are a scam and not the answer.</h2>
                    {/* <a href="https://universalcarbon.com/" target="_blank" rel="noopener noreferrer">
                        <img src="UPCO2-logolight.svg" alt="UPCO2 Token Logo" width="70%"></img>
                    </a> */}
                    <br></br>
                    <h1 className="scamText">CO<sub>2</sub> = $</h1>
                </Col>
                <Col lg={6} className="mb-5">
                    <p className="pPlus">While many sites are well intentioned, there have been reports of scams. That's why we purchase globally traded and certified offset tokens, instead of trusting the chosen projects of any one web app.</p>
                    <br></br>
                    <p className="pPlus">And, no, carbon offsets aren't the answer. The answer is to help you learn how your choices impact your footprint, provide resources to help you move to a carbon free life - and all the while fund useful climate projects, at a very low cost to you.</p>
                </Col>
            </Row>
        </>
    )
}
