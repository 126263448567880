// import React from 'react'
import React, { useEffect } from 'react'

import { Form, Card, ListGroup, ListGroupItem } from 'react-bootstrap'


const imageAddress = "/green-leaf-transparent.png";

let purchaseSaveComplete = "";

function UserData({ selectionChange, state }) {

    // Read the purchaseSaveComplete variable so that the button choice can use it below.
    purchaseSaveComplete = sessionStorage.getItem('purchaseSaveComplete');

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
        selectionChange("entryChanged", 1);
    };

    // function skipFunction() {
    //     alert('In the skip function')
    //     callStateFunction("activeCarouselIndex", 3)
    // };

    useEffect(() => {
        // Read the purchaseSaveComplete variable so that the button choice can use it below.
        purchaseSaveComplete = sessionStorage.getItem('purchaseSaveComplete');
    }, []);

    return (
        <>
            <Card className="mb-3 mx-auto calculatorCards" style={{ width: '20rem' }}>
                <Card.Img variant="top" src={imageAddress} className="calculatorCardImage mt-3" />
                <Card.Body>
                    {/* <i id="infoButton"
                        onClick={handleShow}
                        className="far fa-question-circle"></i> */}

                    {/* <Card.Title>Calculate your carbon footprint for the week, <i className="boldWeight">Simply</i></Card.Title> */}
                    <Card.Title>Let's review your carbon footprint for the week.</Card.Title>
                    <Card.Text>
                        {/* <i>3 simple panels with 3 questions each. To get started, please tell us where you live and how many people you are entering for this week.</i> */}
                        {/* <i>Let's start with the state you live in and how many adults you are entering data for this week.</i> */}
                        <i>Start with the state you live in, how many adults you are entering data for, and how many vehicles were used this week.</i>

                    </Card.Text>
                </Card.Body>
                <ListGroup
                    className="list-group-flush"
                >
                    <ListGroupItem>

                        <Form.Control
                            value={state.state} as="select" custom className="mt-0" id="state" onChange={(e) => callStateFunction("state", e.target.value)}>
                            <option value={1}>Select State</option>
                            <option>Alabama</option>
                            <option>Alaska</option>
                            <option>Arizona</option>
                            <option>Arkansas</option>
                            <option>California</option>
                            <option>Colorado</option>
                            <option>Connecticut</option>
                            <option>Delaware</option>
                            <option>District of Columbia</option>
                            <option>Florida</option>
                            <option>Georgia</option>
                            <option>Hawaii</option>
                            <option>Idaho</option>
                            <option>Illinois</option>
                            <option>Indiana</option>
                            <option>Iowa</option>
                            <option>Kansas</option>
                            <option>Kentucky</option>
                            <option>Louisiana</option>
                            <option>Maine</option>
                            <option>Maryland</option>
                            <option>Massachusetts</option>
                            <option>Michigan</option>
                            <option>Minnesota</option>
                            <option>Mississippi</option>
                            <option>Missouri</option>
                            <option>Montana</option>
                            <option>Nebraska</option>
                            <option>Nevada</option>
                            <option>New Hampshire</option>
                            <option>New Jersey</option>
                            <option>New Mexico</option>
                            <option>New York</option>
                            <option>North Carolina</option>
                            <option>North Dakota</option>
                            <option>Ohio</option>
                            <option>Oklahoma</option>
                            <option>Oregon</option>
                            <option>Pennsylvania</option>
                            <option>Rhode Island</option>
                            <option>South Carolina</option>
                            <option>South Dakota</option>
                            <option>Tennessee</option>
                            <option>Texas</option>
                            <option>Utah</option>
                            <option>Vermont</option>
                            <option>Virginia</option>
                            <option>Washington</option>
                            <option>West Virginia</option>
                            <option>Wisconsin</option>
                            <option>Wyoming</option>

                        </Form.Control>

                        <Form.Control value={state.numPeople} as="select" custom className="mt-3" id="numPeople" onChange={(e) => callStateFunction("numPeople", e.target.value)}>
                            <option value={0}>Select # of adults, age 16+</option>
                            <option value={1}>Entering data for 1 adult</option>
                            <option value={2}>Entering data for 2 adults</option>
                            <option value={3}>Entering data for 3 adults</option>
                            <option value={4}>Entering data for 4 adults</option>
                        </Form.Control>

                        <Form.Control value={state.numVehicles} as="select" custom className="mt-3" id="numVehicles" onChange={(e) => callStateFunction("numVehicles", e.target.value)}>
                            <option value={-1}>Select # of Vehicles</option>
                            <option value={0}>No vehicle travel this week</option>
                            <option value={10}>Only bus or ride sharing</option>
                            <option value={1}>1 vehicle</option>
                            <option value={2}>2 vehicles</option>
                            <option value={3}>3 vehicles</option>
                            <option value={4}>4 vehicles</option>

                        </Form.Control>










                        {/* <Card.Text className="mt-1" style={{ textAlign: "center" }}>
                            <button onClick={skipFunction}> Or, Repeat Last Week's Offset</button>
                        </Card.Text> */}

                    </ListGroupItem>
                </ListGroup>
            </Card>







        </>
    )
}

export default UserData
