import React, { useEffect } from "react";
import { Container, Row, Col, Table, Accordion, Card } from 'react-bootstrap'
import MissionSection from "../../components/MissionSection"
import ContactSection from "../../components/ContactSection"


function Resources({ selectionChange, state }) {

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    useEffect(() => {

        // Set inStripeReturn to false so login and user will be displayed in navbar
        callStateFunction("inStripeReturn", false);

    }, []);

    return (
        <>
            <Container fluid style={{ paddingLeft: '0', paddingRight: '0' }}>


                <Container className="mb-3 p-5" style={{ zIndex: '+1', position: 'relative', background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                    <h1 className="h3Plus" style={{ textAlign: 'center' }}>Carbon Reduction & Climate Change <span className="boldWeight">Resources</span></h1>
                    <Row>
                        <Col lg={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '20px' }}>
                            <img src="leaf-car.png" alt="UPCO2 Symbol" width="100%"></img>
                        </Col>
                        <Col lg={8}>
                            <p>Our goal is not to offset your normal, carbon producing lifestyle.  At Simply Carbon Neutral, our goal is to help the members of our community understand their footprint and work to reduce it, as they fund useful, REDD+ certified, climate protection projects.
                                <br></br>
                                <br></br>
                                Carbon offsetting and carbon credits are not the answer.  We can't simply offset our current lifestyles to net zero.  Instead, we want to guide you to resources that will aid in your journey to a carbon free lifestyle. We hope you find these resources helpful and please <a href="mailto:support@simplycarbonneutral.com">email us</a> if you have recommendations for other resources that would be helpful to the community.
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container className="p-5" style={{ zIndex: '+1', position: 'relative', background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px', marginBottom: '-100px' }}>

                    {/*Home resources table */}
                    <h3>Home</h3>
                    <Table striped bordered hovered>
                        <tbody>
                            <tr>
                                <td>How can I access renewable energy in my area?</td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>Check with your current electricity provider. Most areas now have the ability to opt into receiving your energy from renewable sources.</td>
                            </tr>
                            <tr>
                                <td><a href="https://www.energy.gov/energysaver/buying-clean-electricity" target="_blank">Buying Clean Electricity</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>Government resource with options for accessing renewable energy for your home electricity.</td>
                            </tr>
                            <tr>
                                <td><a href="https://www.energy.gov/energysaver/heating-and-cooling" target="_blank">Energy Saver Heating and Cooling</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>Renewable an high efficiency options for heating and cooling your home, and heating your water.</td>
                            </tr>
                        </tbody>
                    </Table>

                    {/*Transportation resources table */}
                    <h3>Transportation</h3>
                    <Table striped bordered hovered>
                        <tbody>
                            <tr>
                                <td><a href="https://www.c2es.org/content/reducing-your-transportation-footprint/" target="_blank">Reducing Your Transportation Carbon Footprint</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>C2ES (Center for Climate and Energy Solutions) discussion of ways to reduce your transportation footprint.</td>
                            </tr>
                        </tbody>
                    </Table>

                    {/*Life resources table */}
                    <h3>Life</h3>
                    <Table striped bordered hovered>
                        <tbody>
                            <tr>
                                <td><a href="https://climatescience.org/simple-eating-shopping" target="_blank">Eating and Shopping: Daily Habits and Climate Change</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>This short ClimateScience quiz/game gives you simple guidelines and data on how to reduce your carbon footprint related to shopping and food.</td>
                            </tr>
                            <tr>
                                <td><a href="https://www.epa.gov/recycle/reducing-waste-what-you-can-do" target="_blank">Reducing Waste: What You Can Do</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>EPA site with tips for reducing the waste in different areas of your life.</td>
                            </tr>
                            <tr>
                                <td><a href="https://www.epa.gov/recycle/how-do-i-recycle-common-recyclables" target="_blank">How Do I Recycle?</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>EPA site explaining the details of recycling all types of materials, including links to other resources to determine local options.</td>
                            </tr>
                        </tbody>
                    </Table>

                    {/*Climate change resources table */}
                    <h3>Climate Change</h3>
                    <Table striped bordered hovered>
                        <tbody>
                            <tr>
                                <td><a href="https://climate.mit.edu/explainers" target="_blank">MIT Climate Portal - Explainers</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>Short, easily digestible overviews of climate change science, solutions, and related topics.</td>
                            </tr>
                            <tr>
                                <td><a href="https://eapsweb.mit.edu/sites/default/files/Climate_Primer.pdf" target="_blank">MIT Climate Science and Climate Risk Primer</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>A more detailed document explaining the causes and impacts of climate change.</td>
                            </tr>
                            <tr>
                                <td><a href="https://www.c2es.org/" target="_blank">Center for Climate and Energy Solutions (C2ES)</a></td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '15px' }}>Information and policy action on critical climate and energy challenges.</td>
                            </tr>
                        </tbody>
                    </Table>

                </Container>

                <Container fluid style={{ backgroundColor: '#296F50' }}  >
                    <ContactSection />
                </Container>

            </Container>


        </>
    )
}

export default Resources

