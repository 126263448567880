import React from 'react'
import { Container } from 'react-bootstrap'
// import PurchaseCard from "../../components/PurchaseCard"
import Wrapper from "../../components/Wrapper"

function Purchase({ state }) {

    return (
        <Container>
            <Wrapper>
                <h2>TEST</h2>
                {/* <PurchaseCard
                    state={state}
                /> */}
            </Wrapper>
        </Container>
    )
}

export default Purchase
