import React, { useEffect } from "react";
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link';

// import Wrapper from "../../components/Wrapper"
// import TopCards from "../../components/TopCards"
// import AboutCards from "../../components/AboutCards"
import MissionSection from "../../components/MissionSection"
import ContactSection from "../../components/ContactSection"


function About({ selectionChange, state }) {

    const callStateFunction = (variable, value) => {
        selectionChange(variable, value);
    };

    useEffect(() => {

        // Set inStripeReturn to false so login and user will be displayed in navbar
        callStateFunction("inStripeReturn", false);

    }, []);

    return (
        <>
            <Container fluid style={{ paddingLeft: '0', paddingRight: '0' }}>


                <Container className="mb-3 p-5" style={{ zIndex: '+1', position: 'relative', background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}>
                    <h1 className="h3Plus" style={{ textAlign: 'center' }}>The <span className="boldWeight">Bottom Line</span></h1>
                    <Row>
                        <Col lg={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '20px' }}>
                            <img src="green-leaf-transparent.png" alt="Green Leaf with city illustration" width="100%"></img>
                        </Col>
                        <Col lg={8}>
                            <h3>We believe in creating a simple, quick, and low cost way for everyone to understand and offset their carbon footprint each week. <br></br><br></br>This enables each individual to be informed about the choices they make in the week ahead, as they strive to reduce their impact. </h3>
                        </Col>
                    </Row>
                </Container>

                <Container className="p-5" style={{ zIndex: '+1', position: 'relative', background: '#FFFFFF', boxShadow: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)', borderRadius: '10px', marginBottom: '-100px' }}>
                    <h3>Frequently Asked Questions</h3>

                    <Accordion>
                        <Card>
                            {/* <Accordion.Toggle as={Card.Header} eventKey="0"> */}
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Who is Simply Carbon Neutral?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>We are a small, family business in Fort Collins, Colorado, working to help you better understand your footprint, your choices moving forward, and enable you to fund useful projects at a greatly reduced cost.  Given that we don't have a lot of overhead and we utilize globally traded carbon offset tokens instead of researching and partnering with individual projects, we reduce your cost for the same environmental impact vs. other sites. Our goal is to build a community of people helping each other work towards carbon free living.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            {/* <Accordion.Toggle as={Card.Header} eventKey="0"> */}
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                What happens each week?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>Each week you will receive an email with a link to update your carbon footprint for the past week. The simple questions on your Dashboard will be automatically populated with your answers from the previous week.  Checking your answers, purchasing your offset credits, and reviewing your progress only takes a couple of minutes.  Then you're off to another week, informed about how your choices impact your CO<sub>2</sub> production. </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                Why a weekly model? Why not monthly, or a "pay and forget it" subscription?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>The primary goal is to allow our community to be aware of how their choices impact their carbon footprint - and have that knowledge actively inform their choices. We accomplish this by making the weekly check-in simple, quick, and informative. We don't want "pay and forget".  We specifically want an informed community who are constantly learning and decreasing their impact.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card id="policy">
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                What are your Privacy & Data Collection Policies?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>We only store your login information and your carbon offset history.  Your password is encrypted and secure and we don't share your email or name with anyone. Purchasing your offsets is done securely through Stripe and no payment information is saved on Simply Carbon Neutral.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                How is my carbon footprint calculated?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>We gather the following data for each state from the U.S. Energy Information Administration:
                                    <ul> <br></br>
                                        <li>Total carbon dioxide emissions</li>
                                        <li>Electric power carbon dioxide emissions</li>
                                        <li>Residential carbon dioxide emissions</li>
                                    </ul>
                                    In addition, we research the average home size and current adult population for each state. <br></br><br></br>
                                    With this data, and your answers to our simple life questions, we are utilize averages to calculate your approximate CO<sub>2</sub> production for the past week.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                                How is my carbon footprint offset? I don't see carbon offset projects on your site.
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>Instead of researching and sponsoring individual projects, we collect our community's offset purchases and buy Universal Carbon tokens - UPCO2. These tokens are then "retired" which releases the funds to offset your carbon footprint. Each globally traded digital UPCO2 token is tied to a certified REDD+ project preventing rainforest loss or degradation. Through the use this global token, the world market sets the cost of keeping CO<sub>2</sub> out of the atmosphere.  In addition, each token is digitally traceable to the certified project.  By using this method, the Simply Carbon Neutral community is helping build the world carbon offset market and doesn't need to encure the cost of researching and sponsoring individual projects - thus keeping your cost lower.  You will find that with Simply Carbon Neutral your sponsored projects are more certain and your costs are significantly lower. See our <span> </span>
                                    <Link to="/purchases">Offset Records</Link>
                                    <span> </span>page for a detailed list of all of our community offsets.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="6">
                                Are carbon offsets the answer?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body>No. At Simply Carbon Neutral we recognize that just offsetting our carbon footprint is not the solution to our climate challenge. However, by each of us understanding and offsetting our footprint, we are directing much needed funds to worthwhile carbon reduction projects while working towards decreasing our footprint and eventually living carbon neutral.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7">
                                I heard that carbon offset credits are a scam.
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body>Yes, it has been reported that some are. That's why we purchase globally traded and certified offset tokens, instead of trusting the chosen projects of any one web app.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="8">
                                Why is my carbon footprint important and how does it relate to climate change?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="8">
                                <Card.Body>Simply stated, when we burn fossil fuels, we release CO<sub>2</sub> and other greenhouse gases into our atmosphere, which heats our planet over time and damages the environment. See our
                                    <Link to="/resources"> Resources page </Link> to learn more about CO<sub>2</sub> and climate change.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="9">
                                Where can I learn more about reducing my footprint, CO<sub>2</sub> and climate change?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="9">
                                <Card.Body>Please see our
                                    <Link to="/resources"> Resources page </Link> where we are gathering helpful links on these topics for our community.  If you have others you'd like to share with the community, please send them to us at the email link on the Resources page - and thank you!
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="10">
                                What is a Metric Ton?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="10">
                                <Card.Body>A metric ton is equal to 2205 pounds and is the conventional unit of measure when discussing carbon emissions.  For reference, offsetting 4.6 metric tons of CO<sub>2</sub> is roughly equivalent to removing one car from the road for a year.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="11">
                                What are the costs?  How is my total calculated?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="11">
                                <Card.Body>Each week we update the cost per pound of UPCO2 tokens on the global market to offset your carbon footprint.  Then, your total is Your Footprint X Cost Per Pound of UPCO2 + 1.95% Uphold token processing fee + 2.9% and $0.30 to Stripe payment services + 5% of the UPCO2 cost to us at Simply Carbon Neutral to support the site.  Note that with most carbon offset companies, this fee is 15-20% instead of our 5%.  This is because they research and fund their own projects instead of using the global, accredited carbon marketplace that we enable you to utilize.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>


                </Container>

                <Container fluid style={{ marginBottom: '-300px' }} >
                    <MissionSection />
                </Container>


                <Container fluid style={{ backgroundColor: '#296F50' }}  >
                    <ContactSection />
                </Container>

            </Container>

            {/* <Container >
                <Row style={{ textAlign: 'center' }}>
                    <Col lg={3}>
                        <img className="mt-3" src="SimplyCarbonNeutral2.png" height="250px" alt="Simply Carbon Neutral Logo"></img>
                    </Col>
                    <Col lg={9}>
                        <div className="pt-3 px-5 mb-3" style={{ fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}>
                            <h4>The average American can offset their carbon footprint for only $2.50 per week.</h4>
                            <br></br>
                            <h4>With millions taking this simple step, we can direct billions of dollars to climate change programs.</h4>
                            <br></br>
                            <h4>We strive to make this a simple, quick, weekly process, while also learning about your carbon footprint</h4>
                            <br></br>

                        </div>
                    </Col>
                </Row>
                <h1 style={{ textAlign: 'center', fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}>Live Carbon Neutral... <i>Simply</i></h1>

                <Wrapper>
                    <TopCards />
                </Wrapper>
                <Wrapper>
                    <AboutCards />
                </Wrapper>
            </Container> */}
        </>
    )
}

export default About

