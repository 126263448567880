module.exports.footprint = function (state) {

    // Home card section:
    let co2Home = 0;
    let co2Electricity = 0;
    let co2Heat = 0;
    // If they have selected the size of their home, calculate Electricity and Heat CO2 by multiplying the state value by number of people, size of their home, and reducing by any renewables
    if (state.homeSelection !== 1) {
        co2Electricity = state.resElecCO2PerPersonPerSQFT * state.numPeople * state.homeSelection * (1 - (state.electricitySelection / 100));
        co2Heat = state.resHeatCO2PerPersonPerSQFT * state.numPeople * state.homeSelection * (1 - (state.heatingSelection / 100));
    }
    co2Home = co2Electricity + co2Heat;

    // Transportation Card:
    let mpg;
    let co2Transportation = 0;
    let tmpVehicleCO2 = 0;
    // let co2SharingMiles = 0;

    // Need to loop through the number of vehicles, adding to co2Transportation on each loop
    // For now co2SharingMiles (user choosing bus or ride sharing) is left at no CO2 impact.  May add in future
    // Check to see if they have selected 1-4 vehicles

    // First zero out CO2 for all vehicle numbers
    for (let i = 0; i < 4; i++) {
        eval("state.footprintTransportationCar".concat(i + 1) + "= 0;")
    }

    if ((state.numVehicles >= 1) && (state.numVehicles <= 4)) {
        for (let i = 0; i < state.numVehicles; i++) {
            // Check to see if this vehicle is an EV and set the mpg for this loop
            if (eval("state.mpgSelection".concat(i + 1)) === 2) {
                mpg = state.constants[0].evEquivMPG;
            } else {
                mpg = eval("state.mpgSelection".concat(i + 1));
            }
            // Calculate and set the CO2 for this vehicle number - zero if they haven't selected miles yet (milesSelection = 1).
            if (eval("state.milesSelection".concat(i + 1)) === 1) {
                tmpVehicleCO2 = 0;
            } else {
                tmpVehicleCO2 = (eval("state.milesSelection".concat(i + 1)) / mpg) * state.constants[0].CO2PerGallonGas;
            }

            // Now, if this vehicle is electric, reduce the footprint by the % renewables they are using for electricity
            if ((eval("state.mpgSelection".concat(i + 1)) === 2) && (state.electricitySelection !== 1)) {
                tmpVehicleCO2 = (1 - state.electricitySelection / 100) * tmpVehicleCO2;
            }

            // Now set the state variable for this car's footprint
            eval("state.footprintTransportationCar".concat(i + 1) + "= tmpVehicleCO2;")

            // Now update the total transportation CO2
            co2Transportation = co2Transportation + tmpVehicleCO2;
        }
    }

    // Life Card    
    // Diet selection
    let co2Food;
    // Multiply the CO2 per person for their selection by the number of people entering for
    switch (state.dietSelection) {
        case 2:
            co2Food = state.constants[0].CO2PerPersonVegan * state.numPeople;
            break;
        case 3:
            co2Food = state.constants[0].CO2PerPersonVegetarian * state.numPeople;
            break;
        case 4:
            co2Food = state.constants[0].CO2PerPersonNoBeef * state.numPeople;
            break;
        case 5:
            co2Food = state.constants[0].CO2PerPersonOccMeat * state.numPeople;
            break;
        case 6:
            co2Food = state.constants[0].CO2PerPersonHeavyMeat * state.numPeople;
            break;
        default:
            co2Food = 0;
            break;
    }
    // Recycling Selection
    let co2Waste;
    switch (state.recyclingSelection) {
        case 2:
            co2Waste = (state.constants[0].CO2PerPersonWaste - state.constants[0].CO2SavingsAllRecycle) * state.numPeople;
            break;
        case 3:
            co2Waste = (state.constants[0].CO2PerPersonWaste - state.constants[0].CO2SavingsOccRecycle) * state.numPeople;
            break;
        case 4:
            co2Waste = (state.constants[0].CO2PerPersonWaste - state.constants[0].CO2SavingsLittleRecycle) * state.numPeople;
            break;
        case 5:
            co2Waste = (state.constants[0].CO2PerPersonWaste) * state.numPeople;
            break;
        default:
            co2Waste = 0;
            break;
    }
    // Shopping Selection
    let co2Shopping;
    switch (state.consumerSelection) {
        case 2:
            co2Shopping = state.constants[0].CO2PerPersonMicroShopping * state.numPeople;
            break;
        case 3:
            co2Shopping = state.constants[0].CO2PerPersonLittleShopping * state.numPeople;
            break;
        case 4:
            co2Shopping = state.constants[0].CO2PerPersonAveShopping * state.numPeople;
            break;
        case 5:
            co2Shopping = state.constants[0].CO2PerPersonAboveAverageShopping * state.numPeople;
            break;
        case 6:
            co2Shopping = state.constants[0].CO2PerPersonHeavyShopping * state.numPeople;
            break;
        default:
            co2Shopping = 0;
            break;
    }
    // Life Card total
    const co2Life = co2Food + co2Waste + co2Shopping;

    // Grand total
    const calcFootprint = (co2Home + co2Transportation + co2Life);
    return [co2Home, co2Transportation, co2Life, calcFootprint];
};

module.exports.cost = function (footprint, constants) {
    return ((footprint * constants.costRate) * (1 + (constants.upholdFee / 100) + (constants.scnFee / 100)) + constants.stripeFlatFee) / (1 - (constants.stripeFee / 100));
}